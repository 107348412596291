<template>
  <!-- eslint-disable max-len -->
  <section class="vtb-telegram">
    <div class="vtb-telegram__icon">
      <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="66" height="66" rx="33" fill="#0088CC"/>
          <path d="M26.2437 47.1206C25.2106 47.1206 25.3863 46.7305 25.0299 45.7468L21.9922 35.7495L45.3757 21.877" fill="#C8DAEA"/>
          <path d="M26.2383 47.1209C27.0354 47.1209 27.3875 46.7564 27.8326 46.3238L32.0842 42.1897L26.7809 38.9917" fill="#A9C9DD"/>
          <path d="M26.7833 38.9924L39.6336 48.4864C41.1001 49.2956 42.1582 48.8765 42.5236 47.1251L47.7543 22.4759C48.2898 20.3289 46.9359 19.3548 45.5329 19.9917L14.8181 31.8352C12.7215 32.6762 12.734 33.8459 14.436 34.367L22.3181 36.8273L40.566 25.3149C41.4275 24.7925 42.2183 25.0731 41.5694 25.6492" fill="url(#paint0_linear)"/>
        <defs>
          <linearGradient id="paint0_linear" x1="36.0846" y1="32.5087" x2="41.0609" y2="43.8468" gradientUnits="userSpaceOnUse">
            <stop stop-color="#EFF7FC"/>
            <stop offset="1" stop-color="white"/>
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="vtb-telegram__content">
      <div class="vtb-telegram__texts">
        <div class="vtb-telegram__title">
          Получайте уведомления в Телеграме
        </div>
        <div class="vtb-telegram__subtitle">
          Уведомление в телеграм пришлёт наш бот, для этого нужно один раз написать ему.
        </div>
      </div>
      <div class="vtb-telegram__help">
        <ol class="vtb-telegram__list">
          <li>
            Откройте нашего бота <a class="text-link-blue" href="https://t.me/VTBMeetupsBot" target="_blank">@VTBMeetupsBot</a>
          </li>
          <li>
            Используйте для подписки четырехзначной код:<br>
            <div class="vtb-telegram__code">
              {{ tgCode }}
            </div>
          </li>
          <li>
            Бот ответит, если подписка произойдёт успешно
          </li>
        </ol>
      </div>
    </div>
    <button class="vtb-telegram__close" @click="$emit('close')">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 1L1 15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 1L15 15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </section>
</template>

<script>
export default {
  computed: {
    tgCode() {
      return this.$store.state.tgCode;
    },
  },
};
</script>

<style lang="scss">
.vtb-telegram {
  @apply bg-smoke-gray flex flex-col tablet:flex-row items-center px-76 py-52 rounded-2xl mb-24;
  @apply relative;

  &__help {
    @apply text-base leading-24 text-opacity-90 font-vtb-regular;
  }

  &__code {
    @apply bg-white rounded font-proxima-nova text-black w-238;
    @apply mt-8 mb-8 py-12 px-16;
  }

  &__title {
    @apply mt-16 tablet:mt-0 text-32 leading-36 font-vtb-demibold;
  }

  &__subtitle {
    @apply text-base leading-24 font-vtb-book;
    margin-top: 14px;
  }

  &__content {
    @apply tablet:ml-28 tablet:grid tablet:grid-cols-2 tablet:gap-x-84;
  }

  &__list {
    list-style-type: decimal;
     & > li::marker {
      font-weight: bold;
    }

    li {
      @apply pl-12;
    }
  }

  &__close {
    @apply focus:outline-none absolute;
    top: 18px;
    right: 20px;
  }
}
</style>
