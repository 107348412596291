<template>
  <div class="vtb-hero" :class="{ 'vtb-hero--minimized': minimized, 'vtb-hero--bg': !minimized }">
    <div class="container mx-auto">
      <slot name="nav" />
      <div v-if="!minimized" class="vtb-hero__content relative">
        <div class="vtb-hero__texts">
          <div class="vtb-hero__title">
            Митапы
          </div>
          <div class="vtb-hero__desc">
            <div class="vtb-hero__desc-content">
              <div class="vtb-hero__desc-content-text">
                Делимся опытом и рассказываем<br />
                про неожиданные открытия в работе на рынке технологий
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-1"></div>
        <div class="vtb-hero__icons pb-92 laptop:pb-0">
          <div class="vtb-hero__icon">
            <div class="vtb-hero__icon-image w-68 h-68 laptop:h-88 laptop:w-88
              desktop:w-104 desktop:h-104">
              <img style="transform: rotate(-6.9deg)" :src="$options.star" />
            </div>
            <div class="vtb-hero__icon-text" style="transform: rotate(-8.6deg)">
              Крутые спикеры
            </div>
          </div>

          <div class="vtb-hero__icon">
            <div class="vtb-hero__icon-image w-96 h-96
              laptop:h-120 laptop:w-120 desktop:w-140 desktop:h-140">
              <img style="transform: rotate(8.39deg);" :src="$options.heart" />
            </div>
            <div class="vtb-hero__icon-text" style="transform: rotate(8.39deg);">
              Бесплатно
            </div>
          </div>

          <div class="vtb-hero__icon">
            <div class="vtb-hero__icon-image w-92 h-92
            laptop:h-120 laptop:w-120 desktop:w-136 desktop:h-136">
              <img style="transform: rotate(-6.01deg);" :src="$options.playCircle" />
            </div>
            <div class="vtb-hero__icon-text" style="transform: rotate(-6.01deg);" >
              Онлайн
            </div>
          </div>

          <div class="vtb-hero__icon">
            <div class="vtb-hero__icon-image w-104 h-104
            laptop:h-120 laptop:w-120 desktop:w-152 desktop:h-152">
              <img style="transform: rotate(7.27deg);" :src="$options.mic" />
            </div>
            <div class="vtb-hero__icon-text">
              Дискуссии
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vtbVideo from '@/assets/videos/mobile_video.mp4';
import vtbPoster from '@/assets/videos/poster.jpg';

import heart from '@/assets/icons/heart.svg';
import star from '@/assets/icons/star.svg';
import mic from '@/assets/icons/mic.svg';
import playCircle from '@/assets/icons/play-circle.svg';

export default {
  heart,
  star,
  mic,
  playCircle,
  vtbVideo,
  vtbPoster,
  props: {
    minimized: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.vtb-hero {
  @apply relative flex flex-col;
  @apply laptop:h-600;

  &--bg {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @media screen and (min-width: 1200px) {
        height: 885px;
      }
      z-index: 2;
      background: linear-gradient(180deg, rgba(26, 26, 26, 0) 81.25%, #1A1A1A 100%),
        linear-gradient(0deg, rgba(7, 14, 17, 0.87), rgba(7, 14, 17, 0.87));
      display: block;
    }
  }

  &__video {
    @apply h-full laptop:h-600;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 1200px) {
      height: 885px;
    }
    z-index: 1;
    overflow: hidden;

    &:after {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0,0,0,.7);
    }

    video {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0 auto;
      display: block;
    }
  }

  &--minimized {
    @apply laptop:h-110 justify-center;
    background-position: left, 170px -80px, center;
    background-size: cover, auto, cover;
  }

  &__content {
    @apply flex flex-col laptop:flex-row ml-16 laptop:ml-72;
    z-index: 3;
  }

  &__texts {
    @apply pr-16 flex-1 laptop:pr-0 laptop:min-w-500;
  }

  &__icons {
    @apply flex-1 grid grid-cols-2 laptop:py-68 laptop:pr-144 desktop:pr-144 laptop:min-w-500;
    @apply mt-64 laptop:mt-0 desktop:max-w-900 desktop:min-w-600;
  }

  &__icon {
    @apply flex flex-col items-center;
    &-image {
      img {
        @apply w-full h-full;
      }
    }

    &-text {
      @apply mt-8;
      @apply text-white text-opacity-70 text-18 leading-24 font-proxima-nova-bold text-center;
    }
  }

  &__title {
    @apply text-white text-56 leading-120p font-vtb-demibold mt-40;
    @apply tablet:mt-88 tablet:text-88;
  }

  &__desc {
    @apply flex mt-12;
  }

  &__desc-spacer {
    @apply hidden;
    @apply laptop:flex laptop:flex-1;
  }

  &__desc-content {
    @apply flex-1;
  }

  &__desc-content-text {
    @apply text-20 laptop:text-28 leading-150p text-white font-vtb-light;
  }
}
</style>
