<template>
  <div class="vtb-meetup-feedback__grade">
    <div class="vtb-meetup-feedback__grade-title">Как вы оцениваете этот митап?</div>
    <div class="vtb-meetup-feedback__grade-box">
      <button
        v-for="item in 5"
        :key="item"
        :disabled="isDisabled(item)"
        @click="handleClick({ formData: { rating: item }, meetupId })"
        class="vtb-meetup-feedback__grade-button"
      >
        <component
          :is="`Rate${item}`"
          :class="{
            'vtb-meetup-feedback__grade-icon--active': currentGrade === item,
            'not-selected': currentGrade && currentGrade !== item,
          }"
          class="vtb-meetup-feedback__grade-icon"
        />
      </button>
    </div>
  </div>
</template>

<script>
import Rate1 from '@/components/icons/Rate1.vue';
import Rate2 from '@/components/icons/Rate2.vue';
import Rate3 from '@/components/icons/Rate3.vue';
import Rate4 from '@/components/icons/Rate4.vue';
import Rate5 from '@/components/icons/Rate5.vue';

export default {
  name: 'VtbMeetupFeedbackGrade',
  components: {
    Rate1,
    Rate2,
    Rate3,
    Rate4,
    Rate5,
  },
  props: {
    currentGrade: Number,
    handleClick: Function,
    meetupId: Number,
  },
  methods: {
    isDisabled() {
      return !!this.currentGrade;
    },
  },
};
</script>

<style lang="scss" scoped>
.vtb-meetup-feedback {
  &__grade {
    @apply mb-20;

    &-title {
      @apply text-dark-gray text-12 font-bold mb-16;
    }

    &-box {
      @apply flex items-center justify-start;
    }

    &-button {
      @apply mx-16;
      transition: transform 0.2s;

      &:first-child {
        margin-left: 0;
      }

      &:not(:disabled) {
        &:hover {
          & .vtb-meetup-feedback__grade-icon {
            transform: scale(1.1);
          }
        }

        &:focus {
          outline: 0;
        }

        &:active {
          opacity: 0.8;
          outline: none;
        }
      }

      &:disabled {
        cursor: no-drop;
      }
    }

    &-icon {
      @apply laptop:w-36 laptop:h-36 w-28 h-28;
      transition: transform 0.2s;

      &--active {
        transform: scale(1.1);
      }

      &.not-selected {
        filter: grayscale(1);
      }
    }
  }
}
</style>
