<template>
  <router-view />
</template>

<script>
import store from '@/store';

const getMeetups = (to) => {
  store.dispatch('getMeetups', to.query.tag);
  if (to.params.meetupId) {
    store.dispatch('getMeetup', to.params.meetupId);
  }
};

export default {
  beforeRouteEnter(to, from, next) {
    getMeetups(to);
    next();
  },
  beforeRouteUpdate(to, from, next) {
    getMeetups(to);
    next();
  },
};
</script>
