export const formatTime = (dateA, isDate = false) => {
  const date = isDate ? dateA : new Date(dateA);
  return `${(`0${date.getHours()}`).slice(-2)}:${(`0${date.getMinutes()}`).slice(-2)}`;
};

export const formatDate = (dateString, withTime = false, forRecording = false) => {
  const months = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];

  const date = new Date(dateString);
  const nowDate = new Date();

  if (withTime) {
    if (nowDate.getFullYear() === date.getFullYear()) {
      return `${date.getDate()} ${months[date.getMonth()]}, ${(`0${date.getHours()}`).slice(-2)}:${(`0${date.getMinutes()}`).slice(-2)}`;
    }

    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}, ${(`0${date.getHours()}`).slice(-2)}:${(`0${date.getMinutes()}`).slice(-2)}`;
  }

  if (forRecording) {
    let dateNum = date.getDate();
    let monthNum = date.getMonth();

    if (date.getFullYear() >= nowDate.getFullYear()) {
      return `${date.getDate()} ${months[date.getMonth()]}`;
    }

    dateNum = dateNum > 9 ? dateNum : `0${dateNum}`;
    monthNum = monthNum > 9 ? monthNum : `0${monthNum}`;

    return `${dateNum}.${monthNum}.${date.getFullYear()}`;
  }

  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

export const dateSort = (dateKey, asc = false) => (a, b) => {
  const aDate = new Date(a[dateKey]);
  const bDate = new Date(b[dateKey]);
  return asc ? aDate.getTime() - bDate.getTime() : bDate.getTime() - aDate.getTime();
};

export const roundHalf = (num) => Math.round(num * 2) / 2;
