<template>
  <div class="vtb-meetup-feedback__success">
    <div v-if="!selectedBook" class="vtb-meetup-feedback__success-box">
      <div
        v-for="(book, idx) in books"
        :key="`book_${idx}`"
        class="vtb-meetup-feedback__success-book"
      >
        <div class="vtb-meetup-feedback__success-row vtb-meetup-feedback__success-row--content">
          <div class="vtb-meetup-feedback__success-col vtb-meetup-feedback__success-col--image">
            <img :src="book.image" :alt="book.title" class="vtb-meetup-feedback__success-image" />
          </div>
          <div class="vtb-meetup-feedback__success-col">
            <div class="vtb-meetup-feedback__success-title">{{ book.title }}</div>
            <div class="vtb-meetup-feedback__success-author">{{ book.author }}</div>
          </div>
        </div>
        <div class="vtb-meetup-feedback__success-row vtb-meetup-feedback__success-row--links">
          <div
            class="vtb-meetup-feedback__success-title vtb-meetup-feedback__success-title--gray"
          ></div>
          <div class="vtb-meetup-feedback__success-buttons">
            <VtbButton
              @click="selectBook(book, book.file)"
              download
              is-blue
              isSmall
              class="vtb-meetup-feedback__success-button"
            >
              Скачать
            </VtbButton>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="vtb-meetup-feedback__success-box">
      <div class="vtb-meetup-feedback__success-book vtb-meetup-feedback__success-book--static">
        <div class="vtb-meetup-feedback__success-row">
          <div class="vtb-meetup-feedback__success-col vtb-meetup-feedback__success-col--image">
            <img
              :src="selectedBook.image"
              :alt="selectedBook.title"
              class="vtb-meetup-feedback__success-image"
            />
          </div>
          <div class="vtb-meetup-feedback__success-col">
            <div class="vtb-meetup-feedback__success-title">{{ selectedBook.title }}</div>
            <div class="vtb-meetup-feedback__success-author">{{ selectedBook.author }}</div>
            <a :href="selectedBook.file" target="_blank" class="vtb-meetup-feedback__success-link"
              >Скачивание не началось</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VtbButton from '@/components/VtbButton.vue';

export default {
  name: 'VtbMeetupFeedbackSuccess',
  props: {
    books: [Array, Object],
    handleClick: Function,
    selectedBook: Object,
  },
  components: {
    VtbButton,
  },
  data() {
    return {};
  },
  methods: {
    selectBook(book, link) {
      window.open(link, '_blank');
      this.handleClick(book);
    },
  },
};
</script>

<style lang="scss" scoped>
.vtb-meetup-feedback {
  &__success {
    @apply w-full mt-40;

    &-book {
      @apply relative w-full p-8 mx-auto bg-white border border-smoke-gray rounded-lg
              overflow-hidden transition-all duration-300;
      max-width: 332px;

      &--static {
        &:hover {
          @apply bg-light-gray;
        }
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:hover {
        & .vtb-meetup-feedback__success-row--links {
          @apply flex;
          transform: translateY(0%);
        }

        & .vtb-meetup-feedback__success-row--content {
          transform: translateY(50%);
        }
      }
    }

    &-row {
      @apply flex w-full transition-transform duration-300;

      &--links {
        @apply absolute w-full h-full top-0 left-0 items-center justify-center
                flex-col bg-light-gray;
        transform: translateY(-100%);
      }
    }

    &-col {
      &--image {
        @apply mr-32;
      }

      &--content {
        @apply flex flex-col;
        max-width: 400px;
      }
    }

    &-image {
      width: 67px;
      height: 85px;
      object-fit: cover;
    }

    &-title {
      @apply font-bold mb-2 text-special-black;
      font-size: 13px;

      &--gray {
        @apply text-dark-gray;
      }
    }

    &-link {
      @apply font-bold text-12 underline mt-auto mb-10;
      color: #1e4bd2;

      &:hover {
        text-decoration: none;
      }
    }

    &-author {
      @apply text-black mb-16;
      font-size: 13px;
    }

    &-buttons {
      @apply mt-8;
    }

    &-button {
      &:not(:last-child) {
        @apply mr-10;
      }
    }
  }
}
</style>
