<style lang="scss" scoped>
.g-tags {
  @apply flex flex-wrap;
}

.tag {
  @apply mt-8 text-medium-blue font-vtb-regular mr-10 text-opacity-80;
  @apply bg-gray cursor-pointer hover:bg-smoke-gray;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 3px;
}
</style>

<template>
  <div class="g-tags">
    <router-link
      v-for="tag in tags"
      :key="tag"
      class="tag"
      :to="{ name: 'AllMeetups', query: { tag: tag } }"
    >
      {{ tag }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Tags',
  components: {},
  data() {
    return {};
  },
  props: {
    tags: { type: Array },
  },
  methods: {},
  computed: {},
};
</script>
