<template>
  <div class="vtb-subscribe-container fixed left-0 tablet:left-0 bottom-0 h-48 w-full"
    :class="{'tablet:sticky': isSubscribed && !isPastMeetup }">
    <div class="container mx-auto h-48 relative"
      :class="{'tablet:mt-36': isSubscribed && !isPastMeetup }">
        <VtbButton v-if="!isLogined"
          class="absolute bottom-24 tablet:right-24 w-full tablet:w-244"
          is-rounded is-bold is-medium @click="loginAndSubscribe">
          <template v-if="!isBasicAuth" #icon>
            <img :src="$options.geeckoLogo" />
          </template>
          {{ buttonText }}
        </VtbButton>
        <VtbButton
          v-else-if="!isSubscribed && !isPastMeetup"
          class="absolute bottom-24 tablet:right-24 w-full tablet:w-244"
          is-rounded
          is-bold
          is-medium
          @click="subscribeMeetup">
          <template v-if="!isBasicAuth" #icon>
            <img :src="$options.geeckoLogo" />
          </template>
          Зарегистрироваться
        </VtbButton>
        <VtbButton
          v-else-if="isSubscribed && !isPastMeetup"
          class="absolute bottom-24 tablet:right-0 laptop:-right-48 w-full tablet:w-288"
          is-success
          is-rounded
          is-medium
        >
          <template #icon>
            <img :src="$options.check" />
          </template>
          <span class="tablet:hidden">Вы записаны</span>
          <span class="hidden tablet:inline-block">Вы уже записаны на митап</span>
        </VtbButton>
    </div>
</div>
</template>

<script>
import geeckoLogo from '@/assets/geecko-logo.svg';
import check from '@/assets/check-circle.svg';
import chatIcon from '@/assets/icons/chat.svg';

import VtbButton from '@/components/VtbButton.vue';

export default {
  chatIcon,
  geeckoLogo,
  check,
  components: {
    VtbButton,
  },
  props: {
    meetup: Object,
  },
  methods: {
    async subscribeMeetup() {
      await this.$store.dispatch('subscribeMeetup', this.meetup.id);
      // this.$router.push({ name: 'Reminder', params: { meetupId: this.$route.params.meetupId } });
      this.$router.push({ name: 'Meetup', params: { meetupId: this.meetup.id }, query: { isSubscribed: true } });
    },
    loginAndSubscribe() {
      if (window.ym) {
        window.ym(75349582, 'reachGoal', 'reg-start');
      }
      localStorage.setItem('subscribeAfterRegister', this.meetup.id);
      this.$store.dispatch('login', this.meetup.id);
    },
  },
  computed: {
    buttonText() {
      return !this.isPastMeetup ? 'Зарегистрироваться' : 'Смотреть';
    },
    isLogined() {
      return this.$store.getters.isLogined;
    },
    isBasicAuth() {
      return this.$store.getters.isLoginedUnsigned;
    },
    userMeetups() {
      return this.$store.state.currentUserMeetups;
    },
    isSubscribed() {
      const found = this.userMeetups.find((m) => m.id === this.meetup.id);
      return !!found;
    },
    isPastMeetup() {
      const meetupDate = new Date(this.meetup.starts_at);
      return meetupDate.getTime() < Date.now();
    },
    timeDiff() {
      const now = Date.now();
      const dateTo = new Date(this.meetup.starts_at);
      const diff = Math.floor((dateTo.getTime() - now) / 1000);
      const d = 60 * 60 * 24;
      const h = 60 * 60;
      const m = 60;
      const days = Math.floor(diff / d);
      const hours = Math.floor((diff - (days * 60 * 60 * 24)) / h);
      const minutes = Math.floor((diff - (days * 60 * 60 * 24) - (hours * 60 * 60)) / m);

      const plurarize = (n, type) => {
        const nStr = n.toString();
        let toCompare = 0;
        if (nStr.length > 1) {
          toCompare = parseInt(nStr[nStr.length - 1], 10);
        } else {
          toCompare = parseInt(nStr[0], 10);
        }

        if (toCompare === 1) {
          switch (type) {
            case 'd':
              return `${n} день`;
            case 'h':
              return `${n} час`;
            case 'm':
              return `${n} минуту`;
            default:
              return '';
          }
        }

        if (toCompare >= 1 && toCompare < 5) {
          switch (type) {
            case 'd':
              return `${n} дня`;
            case 'h':
              return `${n} часа`;
            case 'm':
              return `${n} минуты`;
            default:
              return '';
          }
        }

        switch (type) {
          case 'd':
            return `${n} дней`;
          case 'h':
            return `${n} часов`;
          case 'm':
            return `${n} минут`;
          default:
            return '';
        }
      };

      const ret = [];

      if (days > 0) {
        ret.push(plurarize(days, 'd'));
      }

      ret.push(plurarize(hours, 'h'));

      if (minutes > 0) {
        ret.push(plurarize(minutes, 'm'));
      }

      return ret.join(' ');
    },
  },
  mounted() {
    if (localStorage.getItem('subscribeAfterRegister')) {
      this.subscribeMeetup();
      localStorage.removeItem('subscribeAfterRegister');
    }
  },
};
</script>

<style lang="scss" scoped>
.vtb-subscribe-container {
  width: calc(100% - 76px);
  padding-left: 16px;
  padding-right: 16px;

  @media screen and (min-width: 768px) {
    width: 100%;
    padding-left: initial;
    padding-right: initial;
  }
}
</style>
