import Vue from 'vue';
import VueGtm from 'vue-gtm';
import Cookie from 'js-cookie';
import { Orion } from '@tailflow/laravel-orion/lib/orion';
import { AuthDriver } from '@tailflow/laravel-orion/lib/drivers/default/enums/authDriver';

import splitTest from '@/utils/splitTest';
import initMetrika from '@/utils/metrika';

import App from './App.vue';
import router from './router';
import store from './store';
import './assets/fonts.scss';
import './assets/tailwind.css';
import './styles/main.scss';

Orion.init(process.env.VUE_APP_API_URL);

declare global {
  interface Window {
    ChatraSetup:any;
    ChatraID:any;
    yaParams: any;
  }
}
const chatraSetup = window.innerWidth < 768 ? {
  buttonStyle: 'round',
  buttonSize: 56,
  colors: {
    buttonText: '#F3F7FA',
    buttonBg: '#2F3441',
  },
  buttonPosition: 'br',
} : {
  colors: {
    buttonText: '#B6B6B6',
    buttonBg: '#1D2029',
  },
  buttonPosition: 'bl',
};

Orion.setAuthDriver(AuthDriver.Sanctum);
const uriParams = new URLSearchParams(window.location.search);
const authToken = uriParams.get('token') || localStorage.getItem('token');
// console.log('authToken', authToken);
if (authToken) {
  localStorage.setItem('token', authToken);
  Orion.setToken(authToken);
}

window.yaParams = {
  abVariant: 'geeckoId',
};

if (process.env.VUE_APP_GTM && process.env.VUE_APP_GTM.length) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM,
  });
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#vtb-app');

if (process.env.VUE_APP_METRIKA) {
  initMetrika();
}

if (process.env.NODE_ENV !== 'development') {
  /* eslint-disable */
  if (window) {
    window.ChatraSetup = {
      groupId: 'QBZrBeEEgHhNrSS6n',
      ...chatraSetup,
    };
    /* eslint-disable */
    (function (d, w, c) {
      w.ChatraID = 'tErhC3R9HAYsJG4no';
      const s = d.createElement('script');
      w[c] = w[c] || function () { (w[c].q = w[c].q || []).push(arguments); };
      s.async = true;
      s.src = 'https://call.chatra.io/chatra.js';
      if (d.head) d.head.appendChild(s);
      // Chatra('setIntegrationData', initVars);
    }(document, window, 'Chatra'));
  }
  /* eslint-enable */
}
