<template>
  <article class="vtb-main-page relative z-10">
    <template v-if="closestMeetup && closestMeetup.id">
      <VtbMeetup
        v-if="!isCurrentMeetupLoading"
        :meetup="closestMeetup"
        :meetups="allMeetups"
      />
      <VtbLoader v-else :outer-height="138" />
    </template>

    <VtbMeetupsList
      v-if="!isAllMeetupsLoading"
      :meetup="closestMeetup"
      :meetups="allMeetups"
    />
    <VtbLoader v-else :outer-height="138" />
  </article>
</template>

<script>
import VtbMeetup from '@/components/VtbMeetup.vue';
import VtbMeetupsList from '@/components/VtbMeetupsList.vue';
import VtbLoader from '@/components/VtbLoader.vue';

export default {
  components: {
    VtbMeetup,
    VtbMeetupsList,
    VtbLoader,
  },
  computed: {
    allMeetups() {
      return this.$store.state.allMeetups;
    },
    closestMeetup() {
      return this.$store.state.currentMeetup;
    },
    isCurrentMeetupLoading() {
      return this.$store.state.isCurrentMeetupLoading;
    },
    isAllMeetupsLoading() {
      return this.$store.state.isAllMeetupsLoading;
    },
  },
};
</script>
