<template>
  <nav
    class="vtb-nav"
    :class="{
      'vtb-nav--minimized': minimized,
      'vtb-nav--mobile': showNav,
      relative: !showNav,
    }"
  >
    <div class="vtb-nav__start flex justify-between">
      <router-link class="z-10" :to="{ name: 'MainPage' }">
        <img class="vtb-nav__logo" :src="$options.vtbLogo" />
      </router-link>
      <!--<div v-if="minimized" class="vtb-nav__text">
        MEETUPS
      </div>-->
      <!--<div class="vtb-nav__spacer" />-->
      <img
        class="vtb-nav__burger"
        v-if="!showNav"
        :src="$options.burger"
        @click="showNav = true"
      />
      <img
        class="vtb-nav__burger mr-32"
        v-else
        :src="$options.cross"
        @click="showNav = false"
      />
    </div>
    <div
      class="vtb-nav__end relative z-10"
      :class="{
        'vtb-nav__end--mobile': showNav == true,
        'desktop:ml-120': !showNav,
      }"
    >
      <router-link
        :to="{ name: 'AllMeetups' }"
        class="vtb-nav__link"
        @click.native="showNav = false"
      >
        Все митапы
      </router-link>
      <router-link
        exact
        :to="{ name: 'MainPage' }"
        class="vtb-nav__link"
        @click.native="showNav = false"
      >
        Ближайший митап
      </router-link>
      <div class="vtb-nav__spacer" />
      <a
        v-if="isLogined"
        class="vtb-nav__link tablet:hidden"
        href="https://id.geecko.com/profile"
        target="_blank"
      >
        Мой профиль
      </a>
      <a
        v-if="!isLogined"
        class="vtb-nav__link"
        @click="
          showNav = false;
          $store.dispatch('login');
        "
      >
        Войти
      </a>
      <template v-else>
        <div class="vtb-nav__avatar-container">
          <div
            class="vtb-nav__avatar"
            :style="avatarStyle"
            v-click-outside="hidePopup"
            @click="showPopup"
          ></div>
          <a
            class="vtb-nav__link tablet:hidden mt-0 ml-16"
            @click="
              showNav = false;
              logout();
            "
          >
            Выйти
          </a>
          <div v-show="popup" class="vtb-nav__dropdown">
            <a
              class="vtb-nav__dropdown-link"
              href="https://id.geecko.com/profile"
              target="_blank"
            >
              Мой профиль
            </a>
            <div class="vtb-nav__dropdown-link" @click="logout">Выйти</div>
          </div>
        </div>
      </template>
    </div>
  </nav>
</template>

<script>
import ClickOutside from 'vue-click-outside';

import vtbLogo from '@/assets/vtb-logo.svg';
import burger from '@/assets/burger.svg';
import cross from '@/assets/cross.svg';

export default {
  vtbLogo,
  burger,
  cross,
  props: {
    minimized: Boolean,
  },
  data() {
    return {
      popup: false,
      showNav: false,
    };
  },
  computed: {
    isLogined() {
      return this.$store.getters.isLogined;
    },
    userPhoto() {
      return this.$store.getters.userPhoto;
    },
    avatarStyle() {
      return {
        backgroundImage: `url("${this.userPhoto || '/user_default.svg'}")`,
        borderWidth: this.userPhoto ? '1px' : '0px',
      };
    },
  },
  methods: {
    showPopup() {
      if (!this.showNav) {
        this.popup = true;
      }
    },
    hidePopup() {
      this.popup = false;
    },
    logout() {
      this.popup = false;
      this.$store.dispatch('logout');
    },
  },
  directives: {
    ClickOutside,
  },
  watch: {
    showNav() {
      if (this.showNav) {
        document.documentElement.style.overflow = 'hidden';
        return;
      }

      document.documentElement.style.overflow = 'auto';
    },
  },
};
</script>

<style lang="scss" scoped>
.vtb-nav {
  @apply mx-16 tablet:mx-32;
  z-index: 6;
  @apply flex flex-row items-center;
  @apply py-24 tablet:py-32;
  @apply border-b border-white border-opacity-14;

  &--minimized {
    @apply py-24;
    @apply border-none;
  }

  &--mobile {
    @apply fixed w-full;
    z-index: 99999;
  }

  &__start {
    @apply flex flex-1 items-center;
    z-index: 11;
  }

  &__text {
    @apply ml-24 text-white text-24 leading-32 font-vtb-demibold;
    letter-spacing: 0.14em;
  }

  &__burger {
    @apply tablet:hidden z-10 cursor-pointer;
  }

  &__end {
    @apply hidden tablet:flex tablet:flex-1;

    &--mobile {
      @apply flex flex-col fixed w-full pt-148 pl-32 bg-black;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        z-index: -1;
        background: radial-gradient(
          ellipse farthest-side at 50% 160%,
          #009fdf 0%,
          black 100%
        );
      }
      transition: all 1s;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;

      .vtb-nav__link {
        @apply text-20 leading-28 border-0 ml-0 text-white text-opacity-100 mr-32 font-vtb-regular;

        &.router-link-active {
          @apply border-0 text-opacity-100;
        }

        &:not(:first-child) {
          @apply mt-48 ml-0;
        }

        &:hover {
          @apply border-0;
        }
      }

      .vtb-nav__avatar-container {
        @apply mt-48;
      }

      .vtb-nav__spacer {
        flex: none;
      }
    }
  }

  &__link {
    @apply text-base text-white text-opacity-56 py-8 font-vtb-demibold;
    @apply cursor-pointer transition-opacity;

    /* &.router-link-active {
      @apply text-opacity-80 border-b border-white border-opacity-30;
      &:hover {
        @apply text-opacity-80 border-b border-white border-opacity-30;
      }
    } */

    &:hover {
      @apply text-opacity-100;
      transition: all 0.1s;
    }

    &:not(:first-child) {
      @apply ml-32;
    }

    &:last-child {
      @apply mt-0;
    }
  }

  &__spacer {
    @apply flex flex-1;
  }

  &__avatar-container {
    @apply relative flex;
    .vtb-nav__link {
      &:not(:first-child) {
        @apply mt-0 ml-16;
        @apply text-24 leading-28 text-white;
      }
    }
  }

  &__avatar {
    @apply w-40 h-40 border border-white border-opacity-20 rounded-full;
    @apply bg-cover bg-center bg-no-repeat cursor-pointer;
  }

  &__dropdown {
    @apply py-8 bg-white rounded-lg absolute right-0 z-0;
    @apply cursor-pointer text-base leading-28 font-vtb-regular text-opacity-90;
    top: calc(100% + 4px);

    &:after {
      content: "";
      width: 8px;
      height: 8px;
      transform: rotate(-45deg);
      background: #fff;
      position: absolute;
      z-index: -1;
      top: -4px;
      left: calc(100% - 24px);
    }
  }

  &__dropdown-link {
    @apply px-16 whitespace-nowrap block;
    &:hover {
      @apply bg-smoke-gray;
    }
  }
}
</style>
