<template>
  <nav class="vtb-breadcrumbs">
    <div v-for="(b, i) in breadcrumbs" :key="i" class="vtb-breadcrumb">
      <router-link class="vtb-breadcrumb__link"
        :class="{'vtb-breadcrumb__link--black': isBlack }"
        :to="{ name: b.name }">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 12L6 8L10 4"
            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ b.title }}
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    isBlack: Boolean,
  },
  computed: {
    breadcrumbs() {
      const routeName = this.$route.name;
      switch (routeName) {
        case 'AllMeetups':
          return [
            {
              title: 'Главная',
              name: 'MainPage',
            },
          ];
        case 'Meetup':
          return [
            {
              title: 'Главная',
              name: 'MainPage',
            },
            /* {
              title: 'Все митапы',
              name: 'AllMeetups',
            }, */
          ];
        default:
          return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vtb-breadcrumbs {
  @apply flex;
}
.vtb-breadcrumb {
  @apply text-white text-14 leading-28 font-vtb-regular;

  &:not(:first-child) {
    @apply ml-4;
  }

  &__link {
    @apply flex items-center;

    &--black  {
      color: #565656;
    }

    svg {
      @apply mr-4;
    }

    &:hover {
      @apply text-blue;
    }
  }
}
</style>
