<template>
  <svg
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 18C36 27.941 27.941 36 18 36C8.06 36 0 27.941 0 18C0 8.06 8.06 0
      18 0C27.941 0 36 8.06 36 18Z"
      :fill="disabled ? '#d2d2d2' : '#FFCC4D'"
    />
    <path
      d="M11.5 18C12.8807 18 14 16.433 14 14.5C14 12.567 12.8807 11 11.5 11C10.1193
      11 9 12.567 9 14.5C9 16.433 10.1193 18 11.5 18Z"
      :fill="disabled ? '#4b4b4b' : '#664500'"
    />
    <path
      d="M24.5 18C25.8807 18 27 16.433 27 14.5C27 12.567 25.8807 11 24.5
      11C23.1193 11 22 12.567 22 14.5C22 16.433 23.1193 18 24.5 18Z"
      :fill="disabled ? '#4b4b4b' : '#664500'"
    />
    <path
      d="M8.66535 27.871C8.84335 28.032 9.10935 28.042 9.30035 27.9C9.33935
      27.871 13.2224 25 18.0004 25C22.7664 25 26.6624 27.871 26.7004 27.9C26.8914
      28.042 27.1574 28.03 27.3354 27.871C27.5124 27.711 27.5524 27.447 27.4294
      27.243C27.3004 27.029 24.2124 22 18.0004 22C11.7884 22 8.69935 27.028 8.57135
      27.243C8.44835 27.448 8.48735 27.711 8.66535 27.871Z"
      :fill="disabled ? '#4b4b4b' : '#664500'"
    />
  </svg>
</template>

<script>
export default {
  name: 'Rate2',
  props: {
    disabled: Boolean,
  },
};
</script>
