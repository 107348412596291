/* eslint-disable camelcase */
import Vue from 'vue';
import VueRouter from 'vue-router';

import { Orion } from '@tailflow/laravel-orion/lib/orion';

import store from '@/store';

import VtbLayout from '../layouts/VtbLayout.vue';

import MainPage from '../pages/MainPage.vue';

import AllMeetupsView from '../pages/AllMeetupsView.vue';
import AllMeetups from '../pages/AllMeetups.vue';
import Meetup from '../pages/Meetup.vue';
import Reminder from '../pages/Reminder.vue';
import SignIn from '../pages/SignToMeetup.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    component: VtbLayout,
    children: [
      {
        path: '/',
        name: 'MainPage',
        component: MainPage,
        beforeEnter: (to, from, next) => {
          store.dispatch('getMeetups');
          store.dispatch('getClosestMeetup');
          next();
        },
      },
      {
        path: '/meetups',
        component: AllMeetupsView,
        children: [
          {
            path: '',
            name: 'AllMeetups',
            component: AllMeetups,
          },
          {
            path: ':meetupId',
            name: 'Meetup',
            component: Meetup,
            beforeEnter: async (to, from, next) => {
              if (store.getters.isLogined) {
                const userMeetup = store.state.currentUserMeetups
                  .find((m) => m.id === +to.params.meetupId);
                if (userMeetup) {
                  try {
                    await store.dispatch('generateCalendarFile', to.params.meetupId);
                    if (to.query.isSubscribed) {
                      await store.dispatch('generateTelegramCode', to.params.meetupId);
                    }
                  } catch (e) {
                    console.error('Error getting calendar link', e);
                  }
                }
                next();
              }
              next();
            },
          },
          {
            path: ':meetupId/signin',
            name: 'SignIn',
            component: SignIn,
            beforeEnter: (to, from, next) => {
              if (store.getters.isLogined) {
                next({ name: 'Reminder', params: { meetupId: to.params.meetupId } });
              } else {
                next();
              }
            },
          },
          {
            path: ':meetupId/reminder',
            name: 'Reminder',
            component: Reminder,
            beforeEnter: (to, from, next) => {
              if (store.getters.isLogined) {
                store.dispatch('generateTelegramCode', to.params.meetupId);
                next();
              } else {
                next({ name: 'Meetup', params: { meetupId: to.params.meetupId } });
              }
            },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

// let orionInit = false;

router.beforeEach(async (to, from, next) => {
  if (process.env.NODE_ENV !== 'development') {
    /* if (!orionInit) {
      await Orion.csrf();
      orionInit = true;
    } */
  }

  await store.dispatch('fetchUser');

  if (!store.state.specializations.length) {
    store.dispatch('fetchEnums');
  }

  if (to.query) {
    const { result, meetup_id } = to.query;
    if (result && ['success_logged_in', 'success_created'].includes(result) && meetup_id) {
      if (window.ym) {
        window.ym(75349582, 'reachGoal', 'reg-done');
      }
      if (window.ym) {
        window.ym(75349582, 'reachGoal', 'signin');
      }
      next({ name: 'Meetup', params: { meetupId: meetup_id }, query: { isSubscribed: true } });
      return;
    }
  }

  next();
});

export default router;
