<template>
  <div class="vtb-meetup-card">
    <div class="vtb-meetup-card__content">
      <div class="vtb-meetup-card__title" ref="title">
        <p>{{ meetup.name }}</p>
      </div>
      <div class="vtb-meetup-card__footer">
        <div class="vtb-meetup-card__date">
          <img
            class="vtb-meetup-card__date-icon"
            :src="meetup.video ? $options.video : $options.date"
          />
          <span
            class="vtb-meetup-card__date-text"
          >
            {{ meetup.video ? `Запись митапа от ${meetupDateRecord}` :  meetupDate}}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Ellipsis from 'ftellipsis';
import { formatDate } from '@/utils';
import date from '@/assets/icons/date.svg';
import video from '@/assets/icons/video.svg';

export default {
  date,
  video,
  props: {
    meetup: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    imageStyle() {
      return {
        backgroundImage: `url("${this.meetup.thumbnail_url}")` || null,
      };
    },
    meetupDate() {
      return formatDate(this.meetup.starts_at, true);
    },
    meetupDateRecord() {
      return formatDate(this.meetup.starts_at, false, true);
    },
  },
  mounted() {
    const ellipsis = new Ellipsis(this.$refs.title);
    ellipsis.calc();
    ellipsis.set();
  },
};
</script>

<style lang="scss" scoped>
.vtb-meetup-card {
  @apply bg-none w-full border border-white border-opacity-40 rounded;

  &:hover {
    background: rgba(255, 255, 255, 0.03);
    transition: all .1s;
  }

  &__content {
    @apply p-16 laptop:p-32 h-full flex flex-col justify-between;
  }

  &__title {
    @apply text-white text-20 leading-120p laptop:text-24 laptop:leading-36  font-vtb-demibold;
    @apply h-120 laptop:h-auto;
    @screen laptop {
      min-height: 216px;
    }
  }

  &__date {
    @apply flex items-center mt-16 laptop:mt-36;

    &-text {
      @apply text-white text-18 leading-24 font-vtb-light text-opacity-70;
    }

    &-icon {
      @apply mr-12;
    }
  }
}
</style>
