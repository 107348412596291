<template>
  <div class="vtb-input">
    <input :value="value"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)" class="vtb-input__inner" />
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.vtb-input {
  @apply w-full;

  &__inner {
    @apply w-full py-10 px-16 text-14 leading-20 rounded font-proxima-nova;
    border: 1px solid #D3DBE8;
  }
}
</style>
