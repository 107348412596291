<template>
  <article class="vtb-all-meetups">
      <section>
        <div class="vtb-container">
          <VtbBreadcrumbs class="vtb-all-meetups__breadcrubms" />
          <div v-if="queryTag" class="vtb-all-meetups__tag">{{ queryTag }}</div>
          <template v-if="!isAllMeetupsLoading">
            <div class="vtb-all-meetups__grid" :class="{'vtb-all-meetups__grid--tag': queryTag }">
              <router-link
                class="vtb-all-meetups__link"
                v-for="(m, idx) in allMeetups"
                :key="idx"
                :to="{name: 'Meetup', params: { meetupId: m.id }}">
                <VtbMeetupCard :meetup="m" />
              </router-link>
            </div>
          </template>
          <VtbLoader :outer-height="369" v-else />
         </div>
      </section>
  </article>
</template>

<script>
import { dateSort } from '@/utils';

import VtbBreadcrumbs from '@/components/VtbBreadcrumbs.vue';
import VtbMeetupCard from '@/components/VtbMeetupCard.vue';
import VtbLoader from '@/components/VtbLoader.vue';

export default {
  components: {
    VtbBreadcrumbs,
    VtbMeetupCard,
    VtbLoader,
  },
  computed: {
    isAllMeetupsLoading() {
      return this.$store.state.isAllMeetupsLoading;
    },
    allMeetups() {
      return [...this.$store.state.allMeetups].sort(dateSort('starts_at'));
    },
    queryTag() {
      return this.$route.query.tag;
    },
  },
};
</script>

<style lang="scss" scoped>
.vtb-all-meetups {
  @apply pt-38 pb-108;
  &__grid {
    @apply mt-16 grid grid-flow-row grid-cols-1 gap-y-40;
    @apply tablet:gap-x-24 tablet:grid-cols-2 laptop:grid-cols-3;

    &--tag {
      @apply mt-40;
    }
  }

  &__link {
    display: flex;
  }

  &__tag {
    @apply mt-8 text-40 leading-48 text-white font-vtb-demibold;
  }
}
</style>
