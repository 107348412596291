<template>
  <form @submit.prevent="handleSubmit">
    <div class="text-dark-gray text-12 font-bold mb-4">
      Порекомендовали бы митап другу или коллеге?
    </div>
    <div class="mb-20">
      <div class="flex items-center mb-4">
        <input
          id="recomend-yes"
          class="cursor-pointer"
          v-model="is_recommended"
          type="radio"
          :value="true"
        />
        <label for="recomend-yes" class="pl-8 cursor-pointer">Да</label>
      </div>
      <div class="flex items-center">
        <input
          id="recomend-no"
          class="cursor-pointer"
          v-model="is_recommended"
          type="radio"
          :value="false"
        />
        <label for="recomend-no" class="pl-8 cursor-pointer">Нет</label>
      </div>
    </div>
    <div class="mb-20">
      <label for="report" class="block text-12 font-semibold mb-4 text-dark-gray">
        Была ли полученная на митапе информация полезной?
      </label>
      <textarea
        v-model="overall"
        id="impression"
        class="w-full p-10 rounded-sm resize-none"
      ></textarea>
    </div>
    <div class="mb-20">
      <label for="wish" class="block text-12 font-semibold mb-4 text-dark-gray">
        Пожелания, рекомендации на будущее
      </label>
      <textarea
        v-model="suggestions"
        id="wish"
        class="w-full p-10 rounded-sm resize-none"
      ></textarea>
    </div>
    <VtbButton is-blue isSmall :disabled="!overall && !suggestions"> Отправить </VtbButton>
  </form>
</template>
<script>
import VtbButton from '@/components/VtbButton.vue';

export default {
  name: 'VtbMeetupFeedbackForm',
  components: { VtbButton },
  props: {
    sendFormFeedback: Function,
    meetupId: Number,
    feedbackId: Number,
  },
  data() {
    return {
      is_recommended: true,
      overall: '',
      suggestions: '',
    };
  },

  computed: {
    rating() {
      return this.$store.state.vtbMeetupFeedback.item.rating;
    },
    formData() {
      return {
        rating: this.rating,
        overall: this.overall,
        is_recommended: this.is_recommended,
        suggestions: this.suggestions,
      };
    },
    userFeedback() {
      return {
        formData: this.formData,
        meetupId: this.meetupId,
        feedbackId: this.feedbackId,
      };
    },
  },
  methods: {
    async handleSubmit() {
      await this.sendFormFeedback(this.userFeedback);
    },
  },
};
</script>
