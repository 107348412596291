<template>
  <article class="vtb-reminder">
    <div class="vtb-reminder__title">
      Вы записались на митап
    </div>
    <div class="vtb-reminder__subtitle">
      Куда прислать напоминание и ссылку перед митапом?
    </div>
    <div class="vtb-reminder__buttons">
      <VtbButton
        :disabled="activeTab === 'email'"
        :is-success="emailApplied"
        is-blue
        class="justify-center"
        @click="applyReminder('email')">
        <template #icon>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1
                21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M22 6L12 13L2 6"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </template>
        {{ emailApplied ? 'Напоминание придет на почту' : 'На почту' }}
      </VtbButton>
      <VtbButton
        :disabled="activeTab === 'telegram'"
        :is-success="telegramApplied"
        is-blue
        class="justify-center"
        @click="setActiveTab('telegram')">
        <template #icon>
          <img :src="$options.telegram" />
        </template>
        {{ telegramApplied ? 'Напоминание придёт в телеграм' : 'В телеграм' }}
      </VtbButton>
    </div>
    <div v-if="activeTab === 'telegram'" class="vtb-reminder__form">
      <div class="vtb-reminder__telegram-help mb-18">
        Уведомление в телеграм пришлёт наш бот, для этого нужно один раз написать ему.
      </div>
      <div class="vtb-reminder__telegram-help mt-16">
        1. Откройте нашего бота <a class="text-link-blue" href="https://t.me/VTBMeetupsBot" target="_blank">@VTBMeetupsBot</a><br/>
        2. Отправьте ему это сообщение:<br>
        <div class="vtb-reminder__telegram-code">
          {{ tgCode }}
        </div>
        3. Бот ответит, если подписка произойдёт успешно
      </div>
      <VtbButton class="mt-28" is-small is-blue @click="applyReminder('telegram')">
        Подтвердить
      </VtbButton>
    </div>
    <div
      v-if="!activeTab && (telegramApplied || emailApplied)"
      class="mt-12 text-dark-gray text-12 leading-28 font-vtb-regular"
    >
      Напоминание придет за 15 минут до начала митапа
    </div>
    <div class="mt-96 mb-96">
      <router-link
        class="vtb-reminder__link"
        :to="{ name: 'Meetup', params: { meetupId: $route.params.meetupId } }">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 12L6 8L10 4"
            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Вернуться на страницу митапа
      </router-link>
    </div>
  </article>
</template>

<script>
import telegram from '@/assets/telegram.svg';
import VtbButton from '@/components/VtbButton.vue';

export default {
  remindTimeOptions: [
    {
      label: 'За 3 дня',
      value: '3days',
    },
    {
      label: 'За 3 часа',
      value: '3hrs',
    },
    {
      label: 'За 30 минут',
      value: '30mins',
    },
    {
      label: 'Утром',
      value: 'morning',
    },
  ],
  telegram,
  components: {
    VtbButton,
  },
  data() {
    return {
      activeTab: null,
      remindTimeEmail: '3days',
      remindTimeTelegram: '3days',
      emailApplied: false,
      telegramApplied: false,
    };
  },
  methods: {
    setActiveTab(tab) {
      if (this.activeTab === tab) {
        this.activeTab = null;
      } else {
        this.activeTab = tab;
      }
    },
    async applyReminder(tab) {
      if (tab === 'email') {
        await this.$store.dispatch('updateReminder', {
          meetupId: this.$route.params.meetupId,
          payload: {
            remind_via_email: true,
            remind_in: this.remindTimeEmail,
          },
        });
        this.emailApplied = true;
      } else if (tab === 'telegram') {
        if (this.$store.getters.isLogined) {
          await this.$store.dispatch('updateReminder', {
            meetupId: this.$route.params.meetupId,
            payload: {
              remind_via_telegram: true,
              remind_in: this.remindTimeTelegram,
            },
          });
        }
        this.telegramApplied = true;
      }

      this.activeTab = null;
    },
  },
  computed: {
    tgCode() {
      return this.$store.state.tgCode;
    },
  },
};
</script>

<style lang="scss" scoped>
.vtb-reminder {
  background: radial-gradient(115.22% 64.11% at 50% 0%, #AEE2B0 0%, rgba(255, 255, 255, 1) 100%);
  min-height: calc(100vh - 351px);
  @apply flex flex-col items-center px-16 rounded-2xl;
  @apply mb-156;

  &__title {
    @apply mt-40 tablet:mt-104 text-40 leading-52 font-vtb-demibold;
    @apply text-center tablet:text-left;
  }

  &__subtitle {
    @apply mt-16 text-base text-black leading-28 text-opacity-90 font-vtb-regular;
    @apply text-center tablet:text-left;
  }

  &__buttons {
    @apply flex flex-col tablet:flex-row mt-32 tablet:mt-24;
    .vtb-button {
      &:not(:first-child) {
        @apply mt-24 tablet:mt-0 tablet:ml-24;
      }
    }
  }

  &__form {
    @apply mt-40 w-328 tablet:w-400 bg-gray rounded-lg px-24 py-18;
  }

  &__link {
    @apply flex items-center;
    @apply text-dark-gray text-14 leading-28 font-vtb-regular;
    svg {
      @apply mr-4;
    }

    &:hover {
      @apply text-blue;
    }
  }

  &__telegram-help {
    @apply text-base leading-24 text-opacity-90 font-vtb-regular;
  }

  &__telegram-code {
    @apply bg-white rounded font-proxima-nova text-black w-238;
    @apply mt-8 mb-8 py-12 px-16;
  }
}
</style>
