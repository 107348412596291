<template>
  <section class="vtb-program">
    <div class="vtb-container">
      <div class="vtb-section-header">
        Программа
      </div>
      <div class="vtb-program__list">
        <div class="vtb-program__list-item" v-for="(row, index) in meetup.speeches" :key="index">
          <div class="flex flex-col items-start
            laptop:flex-row laptop:col-span-6">
            <div class="vtb-program__list-time">
              <div class="vtb-program__list-time-start">
                {{ getFormattedTime(index) }}
              </div>
              <div class="vtb-program__list-time-duration">
                {{plurarize(row.duration)}}
              </div>
            </div>
            <div class="vtb-program__list-text">
              <div class="vtb-program__list-text-title">
                {{ row.title }}
              </div>
              <tags v-if="row.tags && row.tags.length" :tags="row.tags.map(item => item.name)" />
              <div v-if="row.subtitle " class="vtb-program__list-text-description">
                {{ row.subtitle }}
              </div>
            </div>
          </div>
          <div class="laptop:col-start-8 laptop:col-span-4">
            <div v-for="(s, i) in row.speakers" :key="i" class="vtb-program__list-speaker">
              <div class="vtb-program__list-speaker-image" :style="getImageStyle(s)"></div>
              <div class="vtb-program__list-speaker-name">
                {{ s.name }}
              </div>
              <div class="vtb-program__list-speaker-description">
                {{ s.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="vtb-program__table">
        <div class="vtb-program__row">
          <div class="vtb-program__info">
            <div class="vtb-program__section-name">
              Начало встречи
            </div>
            <div class="vtb-program__section-timing">
              {{ meetupDate }}
            </div>
          </div>
          <div class="flex-1"></div>
        </div>
        <div class="vtb-program__row" v-for="(row, index) in meetup.speeches" :key="index">
          <div class="vtb-program__info">
            <div class="vtb-program__section-name">
              {{ row.title }}
            </div>
            <div class="vtb-program__section-timing">
              {{ row.duration }} минут
            </div>
          </div>
          <div class="vtb-program__speakers">
            <div class="vtb-program__speaker" v-for="(s, sIndex) in row.speakers" :key="sIndex">
              <div class="vtb-program__speaker-image" :style="getImageStyle(s)">
              </div>
              <div class="vtb-program__speaker-info">
                <div class="vtb-program__speaker-name">
                  {{ s.name }}
                </div>
                <div class="vtb-program__speaker-position">
                  {{ s.headline }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>-->
    </div>
  </section>
</template>

<script>
import noPhoto from '@/assets/no-photo.jpg';

import geeckoLogo from '@/assets/geecko-logo.svg';
import check from '@/assets/check.svg';

import { formatTime } from '@/utils';
import Tags from './Tags.vue';

export default {
  geeckoLogo,
  check,
  props: {
    meetup: Object,
  },
  components: {
    Tags,
  },
  computed: {
    buttonText() {
      return this.$route.name === 'MainPage' ? 'Зарегистрироваться' : 'Смотреть';
    },
    isLogined() {
      return this.$store.getters.isLogined;
    },
    userMeetups() {
      return this.$store.state.currentUserMeetups;
    },
    isSubscribed() {
      const found = this.userMeetups.find((m) => m.id === this.meetup.id);
      return !!found;
    },
    isPastMeetup() {
      const meetupDate = new Date(this.meetup.starts_at);
      return meetupDate.getTime() < Date.now();
    },
    meetupDate() {
      return formatTime(this.meetup.starts_at, true);
    },
  },
  methods: {
    async subscribeMeetup() {
      await this.$store.dispatch('subscribeMeetup', this.meetup.id);
      this.$router.push({ name: 'Meetup', params: { meetupId: this.meetup.id }, query: { isSubscribed: true } });
    },
    getImageStyle(speaker) {
      return {
        background: `linear-gradient(0deg, rgba(94, 18, 153, 0.1), rgba(94, 18, 153, 0.1)),
          url(${speaker.photo_url || noPhoto})`,
        backgroundSize: 'cover',
      };
    },
    getFormattedTime(idx) {
      if (idx === 0) {
        return formatTime(this.meetup.starts_at);
      }

      const prevDuration = this.meetup.speeches.reduce((acc, current, index) => {
        if (index < idx) {
          // eslint-disable-next-line no-param-reassign
          acc += current.duration;
        }

        return acc;
      }, 0);

      const dateStart = new Date(this.meetup.starts_at);
      return formatTime(new Date(dateStart.getTime() + prevDuration * 60 * 1000), true);
    },
    plurarize(num) {
      const plurarize = (n, type) => {
        const nStr = n.toString();
        let toCompare = 0;
        if (nStr.length > 1) {
          toCompare = parseInt(nStr[nStr.length - 1], 10);
        } else {
          toCompare = parseInt(nStr[0], 10);
        }

        if (toCompare === 1) {
          switch (type) {
            case 'd':
              return `${n} день`;
            case 'h':
              return `${n} час`;
            case 'm':
              return `${n} минуту`;
            default:
              return '';
          }
        }

        if (toCompare >= 1 && toCompare < 5) {
          switch (type) {
            case 'd':
              return `${n} дня`;
            case 'h':
              return `${n} часа`;
            case 'm':
              return `${n} минуты`;
            default:
              return '';
          }
        }

        switch (type) {
          case 'd':
            return `${n} дней`;
          case 'h':
            return `${n} часов`;
          case 'm':
            return `${n} минут`;
          default:
            return '';
        }
      };

      return plurarize(num, 'm');
    },
  },
};
</script>

<style lang="scss" scoped>
.vtb-program {
  @apply bg-white pt-40 tablet:pt-64 pb-48 tablet:pb-40 mt-16 rounded-2xl;

  &__list {
    @apply mt-48 laptop:mt-80;
  }

  &__table {
    @apply mt-42;
  }

  &__row {
    @apply flex flex-col py-24 border-b border-smoke-gray;
    @apply tablet:flex-row tablet:items-center;
  }

  &__info {
    @apply flex-1 self-start;
  }

  &__speakers {
    @apply flex-1 mt-16 rounded-lg bg-smoke-gray p-16;
    @apply tablet:mt-0 tablet:rounded-none tablet:bg-transparent;
    @apply tablet:p-0 tablet:ml-24 desktop:ml-120;
  }

  &__section-name {
    @apply text-black text-base leading-28 font-vtb-demibold;
  }

  &__section-timing {
    @apply mt-4 text-black text-14 leading-18 font-vtb-light text-opacity-70;
  }

  &__speaker {
    @apply flex items-center;

    &:not(:first-child) {
      @apply mt-16;
    }
  }

  &__speaker-image {
    @apply rounded-lg w-48 h-48;
    min-height: 48px;
    min-width: 48px;
    background-blend-mode: screen, normal;
  }

  &__speaker-info {
    @apply ml-16;
  }

  &__speaker-name {
    @apply text-14 leading-18 font-vtb-demibold;
  }

  &__speaker-position {
    @apply mt-4 text-14 leading-18 font-vtb-light text-opacity-70;
  }

  &__button {
    @apply mt-40;
  }

  &__list-item {
    @apply grid grid-cols-1 laptop:grid-cols-12 pb-32 laptop:pb-56;

    &:not(:last-child) {
      @apply border-b border-opacity-20 border-black;
    }

    &:not(:first-child) {
      @apply pt-32;
    }
  }

  &__list-time-start {
    @apply text-32 leading-44 font-vtb-demibold laptop:text-center;
  }

  &__list-time-duration {
    @apply text-14 leading-22 font-vtb-regular text-opacity-90 laptop:text-center;
  }

  &__list-text {
    @apply mt-16 laptop:mt-0 laptop:ml-64;
  }

  &__list-text-title {
    @apply text-24 leading-32 font-vtb-demibold;
  }

  &__list-text-subtitle {
    @apply text-24 leading-150p font-vtb-regular text-opacity-90;
  }

  &__list-text-description {
    @apply mt-8 text-18 leading-150p text-opacity-80;
  }

  &__list-speaker {
    &:first-child {
      @apply mt-24 laptop:mt-0;
    }
    &:not(:first-child) {
      @apply mt-38;
    }
  }

  &__list-speaker-image {
    @apply w-88 h-88 rounded-3xl;
  }

  &__list-speaker-name {
    @apply text-14 leading-130p font-vtb-demibold mt-16;
  }

  &__list-speaker-description {
    @apply text-14 leading-130p font-vtb-light text-opacity-70 mt-4;
  }
}
</style>
