<template>
  <svg
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 18C36 27.941 27.941 36 18 36C8.059 36 0 27.941 0 18C0 8.059
      8.059 0 18 0C27.941 0 36 8.059 36 18Z"
      :fill="disabled ? '#d2d2d2' : '#FFCC4D'"
    />
    <path
      d="M22.0008 27.0001C22.0008 29.7631 20.2098 30.0001 18.0008 30.0001C15.7908
      30.0001 14.0008 29.7631 14.0008 27.0001C14.0008 24.2391 15.7908 21.0001 18.0008
      21.0001C20.2098 21.0001 22.0008 24.2391 22.0008 27.0001ZM30.0008 15.0001C29.8768
      15.0001 29.7508 14.9771 29.6298 14.9281C24.4008 12.8371 22.2578 9.68708 22.1688
      9.55408C21.8618 9.09408 21.9858 8.47308 22.4458 8.16708C22.9048 7.86108 23.5228
      7.98308 23.8308 8.44108C23.8498 8.46808 25.7608 11.2261 30.3718 13.0701C30.8848
      13.2761 31.1348 13.8571 30.9298 14.3701C30.7728 14.7621 30.3968 15.0001 30.0008
      15.0001ZM6.00078 15.0001C5.60378 15.0001 5.22878 14.7621 5.07178 14.3711C4.86678
      13.8581 5.11578 13.2761 5.62878 13.0711C10.2408 11.2271 12.1518 8.46908 12.1708
      8.44208C12.4788 7.98608 13.0998 7.86508 13.5578 8.17208C14.0148 8.48008 14.1388
      9.09708 13.8328 9.55508C13.7438 9.68808 11.6008 12.8381 6.37278 14.9291C6.25078
      14.9771 6.12478 15.0001 6.00078 15.0001Z"
      :fill="disabled ? '#4b4b4b' : '#664500'"
    />
    <path
      d="M24 16H28V35L24 34.954V16ZM8 35L12 34.954V16H8V35Z"
      :fill="disabled ? '#a6a6a6' : '#5DADEC'"
    />
    <path
      d="M14.9988 18.0001C14.8488 18.0001 14.6958 17.9661 14.5528 17.8951C11.0408 16.1391
      7.48284 17.8771 7.44784 17.8951C6.95284 18.1441 6.35284 17.9411 6.10584 17.4481C5.85884
      16.9541 6.05884 16.3531 6.55284 16.1061C6.73484 16.0161 11.0508 13.9091
      15.4478 16.1061C15.9418 16.3531 16.1418 16.9541 15.8948 17.4481C15.7188 17.7981
      15.3658 18.0001 14.9988 18.0001ZM28.9988 18.0001C28.8488 18.0001 28.6958
      17.9661 28.5528 17.8951C25.0398 16.1391 21.4828 17.8771 21.4478
      17.8951C20.9538 18.1431 20.3538 17.9421 20.1058 17.4481C19.8588
      16.9541 20.0588 16.3531 20.5528
      16.1061C20.7348 16.0161 25.0538 13.9101 29.4478 16.1061C29.9418
      16.3531 30.1418 16.9541 29.8948
      17.4481C29.7188 17.7981 29.3658 18.0001 28.9988 18.0001Z"
      :fill="disabled ? '#4b4b4b' : '#664500'"
    />
    <path
      d="M18 36C27.9411 36 36 35.1046 36 34C36 32.8954 27.9411 32 18 32C8.05887
      32 0 32.8954 0 34C0 35.1046 8.05887 36 18 36Z"
      :fill="disabled ? '#a6a6a6' : '#5DADEC'"
    />
    <path
      d="M18 29C19.6569 29 21 28.1046 21 27C21 25.8954 19.6569 25 18
      25C16.3431 25 15 25.8954 15 27C15 28.1046 16.3431 29 18 29Z"
      :fill="disabled ? '#a6a6a6' : '#E75A70'"
    />
  </svg>
</template>

<script>
export default {
  name: 'Rate1',
  props: {
    disabled: Boolean,
  },
};
</script>
