<template>
  <section class="vtb-reminder">
    <div class="vtb-reminder__title">
      Вы записались на митап
    </div>
    <div class="vtb-reminder__subtitle">
      Мы пришлём напоминание на почту в день митапа
    </div>
    <div class="vtb-reminder__buttons">
      <a class="vtb-reminder__button" :href="calendarLink" target="_blank"
      @click="addCalendar">
        <span class="vtb-reminder__button-icon">
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <!-- eslint-disable max-len -->
            <path d="M12.6667 3.1665H3.33333C2.59695 3.1665 2 3.76346 2 4.49984V13.8332C2 14.5695 2.59695 15.1665 3.33333 15.1665H12.6667C13.403 15.1665 14 14.5695 14 13.8332V4.49984C14 3.76346 13.403 3.1665 12.6667 3.1665Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.668 1.8335V4.50016" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.33203 1.8335V4.50016" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2 7.1665H14" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
        <span class="vtb-reminder__button-text">
          Добавить в календарь
        </span>
      </a>
    </div>
    <button class="vtb-reminder__close" @click="$emit('close')">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 1L1 15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 1L15 15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </section>
</template>

<script>
export default {
  computed: {
    googleCalendarLink() {
      return this.$store.state.calendarLink;
    },
    calendarLink() {
      return `${process.env.VUE_APP_API_URL}api/meetups/${this.$route.params.meetupId}/calendar`;
    },
  },
  methods: {
    addCalendar() {
      if (window.ym) {
        window.ym(75349582, 'reachGoal', 'add-calendar');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vtb-reminder {
  background: radial-gradient(115.22% 64.11% at 50% 0%, #AEE2B0 0%, rgba(255, 255, 255, 1) 100%);
  @apply flex flex-col items-center px-16 rounded-2xl mb-24;
  @apply relative pb-56;

  &__title {
    @apply mt-40 text-40 leading-52 font-vtb-demibold;
    @apply text-center tablet:text-left;
  }

  &__subtitle {
    @apply mt-16 text-base text-black leading-24 text-opacity-90 font-vtb-regular;
    @apply text-center tablet:text-left;
  }

  &__close {
    @apply focus:outline-none absolute;
    top: 18px;
    right: 20px;
  }

  &__buttons {
    @apply mt-20 flex justify-center;
  }

  &__button {
    @apply bg-reminder-button hover:bg-reminder-button-hover flex items-center;
    @apply py-4 px-8 text-white text-14 leading-22 font-vtb-book rounded;
    @apply focus:outline-none focus:bg-reminder-button-pressed;
  }

  &__button-text {
    @apply ml-8;
  }
}
</style>
