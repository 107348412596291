<template>
  <div class="vtb-form-field" :class="{'vtb-form-field--required': required}">
    <div class="vtb-form-field__label">
      {{ label }}
    </div>
    <div class="vtb-form-field__input">
      <slot />
    </div>
    <div v-if="errorText" :style="fieldStyle" class="vtb-form-field__error">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    required: Boolean,
    errorText: String,
    hasError: Boolean,
  },
  computed: {
    fieldStyle() {
      if (this.errorText) {
        if (this.hasError) {
          return {
            visibility: 'visible',
          };
        }
        return {
          visibility: 'hidden',
        };
      }

      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
.vtb-form-field {
  &__label {
    @apply text-12 leading-20 text-input-gray;
  }

  &__error {
    @apply mt-4 mb-4 text-12 leading-20 text-red-button;
  }

  &--required {
    .vtb-form-field__label {
      &:after {
        content: '*';
        @apply ml-4 text-red;
      }
    }
  }
}
</style>
