<template>
  <button
    v-if="!to"
    class="vtb-button"
    :class="{
      'vtb-button--icon': !!$slots.icon,
      'vtb-button--small' : isSmall,
      'vtb-button--medium' : isMedium,
      'vtb-button--disabled' : disabled,
      'vtb-button--success' : isSuccess,
      'vtb-button--blue' : isBlue,
      'vtb-button--dark' : isDark,
      'vtb-button--interactive': !disabled && !isSuccess,
      'rounded-3xl': isRounded,
      'rounded': !isRounded,
      'font-proxima-nova-bold': isBold,
    }"
    @click="$emit('click', $event)">
    <slot name="icon" />
    <span class="vtb-button__text">
      <slot />
    </span>
  </button>
  <router-link
    v-else
    class="vtb-button vtb-button--link"
    :class="{
      'vtb-button--icon': !!$slots.icon,
      'vtb-button--small' : isSmall,
      'vtb-button--disabled' : disabled,
      'vtb-button--success' : isSuccess,
      'vtb-button--blue' : isBlue,
      'vtb-button--interactive': !disabled && !isSuccess,
      'rounded-3xl': isRounded,
      'rounded': !isRounded
    }"
    :to="to"
    @click="$emit('click', $event)"
    >
    <slot name="icon" />
    <span class="vtb-button__text">
      <slot />
    </span>
  </router-link>
</template>

<script>
export default {
  props: {
    isSmall: Boolean,
    isSuccess: Boolean,
    isDark: Boolean,
    isBlue: Boolean,
    isRounded: Boolean,
    isBold: Boolean,
    isMedium: Boolean,
    to: {
      type: Object,
      default: () => null,
    },
    disabled: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.vtb-button {
  @apply bg-red-button px-20 py-8 text-white font-proxima-nova;
  @apply text-base leading-24 border-4 border-transparent outline-none;

  &--icon {
    @apply flex;

    .vtb-button__text {
      @apply ml-16;
    }
  }

  &--link {
    @apply inline-block;
  }

  &--small {
    @apply px-8 py-0 text-14;
  }

  &--medium {
    @apply px-12;
    .vtb-button__text {
      @apply ml-8;
    }
    @apply min-w-216 justify-center;
  }

  &--blue {
    @apply bg-blue text-white;
  }

  &--success {
    @apply text-success bg-success-light;

    &.vtb-button--dark {
      @apply text-success-dark;
    }
  }

  &--interactive {
    transition: all .1s;
    &:hover {
      @apply bg-red-button-hover;
    }

    &:active {
      @apply bg-red-button-pressed;
    }

    &:focus {
      @apply border-red-button-focus;
    }

    &.vtb-button--blue {
      &:hover {
        @apply bg-blue-hover;
      }

      &:active {
        @apply bg-blue-pressed;
      }

      &:focus {
        @apply border-blue-focus;
      }
    }
  }

  &--disabled {
    @apply text-dark-gray bg-smoke-gray cursor-not-allowed;
  }
}
</style>
