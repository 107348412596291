<template>
  <article class="vtb-sign-to-meetup">
    <div class="vtb-sign-to-meetup__title">
      Запись на митап
    </div>
    <div class="vtb-sign-to-meetup__form">
      <VtbFormField
        required
        label="Email"
        :has-error="emailError"
        error-text="Email уже зарегистрирован"
      >
        <VtbInput v-model="email" />
      </VtbFormField>
      <VtbFormField required label="Имя">
        <VtbInput v-model="name" />
      </VtbFormField>
      <VtbButton class="mt-28"
        is-small is-blue :disabled="!isEmailValid || !isNameValid"
        @click="register"
      >
        Продолжить
      </VtbButton>
    </div>
    <div class="mt-64 mb-64">
      <router-link
        class="vtb-sign-to-meetup__link"
        :to="{ name: 'Meetup', params: { meetupId: $route.params.meetupId } }">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 12L6 8L10 4"
            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Вернуться на страницу митапа
      </router-link>
    </div>
  </article>
</template>

<script>
import VtbFormField from '@/components/VtbFormField.vue';
import VtbInput from '@/components/VtbInput.vue';
import VtbButton from '@/components/VtbButton.vue';

/* eslint-disable */
const emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
/* eslint-enable */

export default {
  data() {
    return {
      email: '',
      name: '',
      emailError: false,
    };
  },
  components: {
    VtbFormField,
    VtbInput,
    VtbButton,
  },
  computed: {
    isEmailValid() {
      return this.email.length && emailRe.test(this.email);
    },
    isNameValid() {
      return this.name.length;
    },
  },
  methods: {
    async register() {
      const { email, name } = this;
      this.emailError = false;
      try {
        const regRes = await this.$store.dispatch('register', { email, name, meetup_id: this.$route.params.meetupId });
        if (regRes.status === 201) {
          if (window.ym) {
            window.ym(75349582, 'reachGoal', 'reg-done');
          }
          await this.$store.dispatch('fetchUser');
          if (window.ym) {
            window.ym(75349582, 'reachGoal', 'signin');
          }
          this.$router.push({ name: 'Reminder', params: { meetupId: this.$route.params.meetupId } });
        } else {
          this.emailError = true;
        }
      } catch (e) {
        this.emailError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vtb-sign-to-meetup {
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  min-height: calc(100vh - 263px);
  @apply flex flex-col items-center px-16 rounded-2xl;
  @apply mb-156;

  &__title {
    @apply mt-40 tablet:mt-104 text-40 leading-52 font-vtb-demibold;
    @apply text-center tablet:text-left;
  }

  &__subtitle {
    @apply mt-16 text-base text-black leading-28 text-opacity-90 font-vtb-regular;
    @apply text-center tablet:text-left;
  }

  &__buttons {
    @apply flex flex-col tablet:flex-row mt-32 tablet:mt-24;
    .vtb-button {
      &:not(:first-child) {
        @apply mt-36 tablet:mt-0 tablet:ml-24;
      }
    }
  }

  &__form {
    @apply mt-40 w-328 tablet:w-400 bg-gray rounded-lg px-24 py-18;
  }

  &__link {
    @apply flex items-center;
    @apply text-dark-gray text-14 leading-28 font-vtb-regular;
    svg {
      @apply mr-4;
    }

    &:hover {
      @apply text-blue;
    }
  }

  &__telegram-help {
    @apply text-base leading-24 text-opacity-90 font-vtb-regular;
  }

  &__telegram-code {
    @apply bg-white rounded font-proxima-nova text-black w-238;
    @apply mt-8 mb-8 py-12 px-16;
  }
}
</style>
