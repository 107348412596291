<template>
<div class="container mx-auto">
  <footer class="vtb-footer">
      <div class="vtb-footer__logos">
        <a class="vtb-footer__logo" href="https://www.vtb.ru/" target="_blank">
          <img :src="$options.vtbLogoBlue" />
        </a>
        <a class="vtb-footer__logo" href="https://geecko.ru/" target="_blank">
          <img style="height: 22px;" :src="$options.geeckoLogo" />
        </a>
      </div>
      <div class="vtb-footer__links">
        <a class="vtb-footer__link"
          href="/docs/policy.pdf" target="_blank">Политика конфиденциальности</a>
        <a
          class="vtb-footer__link"
          href="/docs/user-agreement.pdf" target="_blank">Пользовательское соглашение</a>
      </div>
  </footer>
  </div>
</template>

<script>
import vtbLogoBlue from '@/assets/vtb-logo.svg';
import geeckoLogo from '@/assets/geeckoLogo.svg';

export default {
  vtbLogoBlue,
  geeckoLogo,
};
</script>

<style lang="scss" scoped>
.vtb-footer {
  @apply border-t border-smoke-gray;
  @apply flex flex-col tablet:flex-row tablet:items-center;
  @apply py-28 px-16 tablet:px-52 tablet:px-48 laptop:px-84;

  &__logos {
    @apply flex items-center;
  }

  &__logo {
    @apply w-56;
    &:not(:first-child) {
      @apply ml-28;
    }
  }

  &__links {
    @apply mt-32 flex flex-col tablet:flex-row tablet:mt-0 tablet:ml-56 laptop:ml-60;
  }

  &__link {
    @apply text-white text-14 leading-28 font-vtb-regular;
    &:not(:first-child) {
      @apply mt-8 tablet:ml-24 tablet:mt-0;
    }
  }
}
</style>
