<template>
  <section class="vtb-meetups-list">
    <div class="vtb-container">
      <template v-if="nextMeetups.length">
        <div class="vtb-section-header vtb-meetups-list__title">
          Следующие митапы
        </div>
        <div class="vtb-meetups-list__grid">
          <router-link
            class="vtb-meetups-list__link"
            v-for="(m, idx) in nextMeetups"
            :key="idx"
            :to="{name: 'Meetup', params: { meetupId: m.id }}">
            <VtbMeetupCard :meetup="m" />
          </router-link>
        </div>
      </template>
      <template v-if="previousMeetups.length">
        <div class="vtb-section-header vtb-meetups-list__title">
          Прошедшие митапы
        </div>
        <div class="vtb-meetups-list__grid">
          <router-link
            class="vtb-meetups-list__link"
            v-for="(m, idx) in previousMeetups"
            :key="idx"
            :to="{name: 'Meetup', params: { meetupId: m.id }}">
            <VtbMeetupCard :meetup="m" />
          </router-link>
        </div>
      </template>
      <VtbButton :to="{ name: 'AllMeetups' }"  is-blue class="vtb-meetups-list__button">
        Смотреть все митапы
      </VtbButton>
    </div>
  </section>
</template>

<script>
import VtbButton from '@/components/VtbButton.vue';
import VtbMeetupCard from '@/components/VtbMeetupCard.vue';

import { dateSort } from '@/utils';

export default {
  props: {
    meetup: Object,
    meetups: Array,
  },
  components: {
    VtbMeetupCard,
    VtbButton,
  },
  computed: {
    nextMeetups() {
      const next = [...this.meetups].sort(dateSort('starts_at', true)).filter((m) => new Date(m.starts_at) > Date.now())
        .filter((m) => m.id !== this.meetup.id);
      return next.slice(0, 3);
    },
    previousMeetups() {
      const prev = [...this.meetups].sort(dateSort('starts_at')).filter((m) => new Date(m.starts_at) < Date.now())
        .filter((m) => m.id !== this.meetup.id);
      return prev.slice(0, 3);
    },
  },
};
</script>

<style lang="scss" scoped>

.vtb-meetups-list {
  @apply py-48 laptop:py-76;

  &__title {
    @apply text-white text-24 leading-120p mt-48 laptop:mt-96 laptop:text-48 laptop:leading-44;
    &:first-child {
      @apply mt-0;
    }
  }

  &__link {
    display: flex;
  }

  &__grid {
    @apply mt-24 laptop:mt-68 grid grid-cols-1 auto-rows-min gap-y-24
      tablet:gap-x-24 tablet:grid-cols-2 laptop:grid-cols-3;
  }

  &__button {
    @apply mt-40;
  }
}
</style>
