<template>
  <div class="vtb-layout">
    <VtbHero :minimized="routeName !== 'MainPage'">
      <template #nav>
        <VtbNav :minimized="routeName !== 'MainPage'" />
      </template>
    </VtbHero>
    <div v-if="isLogined
      && routeName == 'Meetup' && (showReminder || showTelegram)" class="container mx-auto">
      <VtbReminder v-if="showReminder" @close="closeReminder" />
      <VtbTelegram v-if="showTelegram" @close="closeTelegram" />
    </div>
    <div class="container mx-auto">
      <router-view />
    </div>
    <VtbFooter />
  </div>
</template>

<script>
import VtbNav from '@/components/VtbNav.vue';
import VtbHero from '@/components/VtbHero.vue';
import VtbFooter from '@/components/VtbFooter.vue';
import VtbReminder from '@/components/VtbReminder.vue';
import VtbTelegram from '@/components/VtbTelegram.vue';

export default {
  data() {
    return {
      showReminder: false,
      showTelegram: false,
    };
  },
  components: {
    VtbNav,
    VtbHero,
    VtbFooter,
    VtbReminder,
    VtbTelegram,
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    isLogined() {
      return this.$store.getters.isLogined;
    },
  },
  methods: {
    closeReminder() {
      this.showReminder = false;
    },
    closeTelegram() {
      this.showTelegram = false;
    },
  },
  mounted() {
    if (this.$route.query.isSubscribed) {
      this.showReminder = true;
      this.showTelegram = true;
      const newURL = window.location.href.split('?')[0];
      window.history.pushState('object', document.title, newURL);
    }
  },
  watch: {
    '$route.name': function () {
      if (this.showReminder || this.showTelegram) {
        this.showReminder = false;
        this.showTelegram = false;
      }
    },
    '$route.query.isSubscribed': function (newVal) {
      if (newVal) {
        this.showReminder = true;
        this.showTelegram = true;
        const newURL = window.location.href.split('?')[0];
        window.history.pushState('object', document.title, newURL);
      }
    },
  },
};
</script>

<style lang="scss">
.vtb-layout {
  background: #1A1A1A;
}

.vtb-wrapper {
  max-width: 1240px;
}

.vtb-container {
  @apply mx-16 laptop:mx-72;
}

.vtb-section-header {
  @apply text-24 leading-120p laptop:text-48 laptop:leading-44 font-vtb-demibold;
}
</style>
