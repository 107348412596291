import Vue from 'vue';
import Vuex from 'vuex';

import { Orion } from '@tailflow/laravel-orion/lib/orion';

import { SortDirection } from '@tailflow/laravel-orion/lib/drivers/default/enums/sortDirection';
import { FilterOperator } from '@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator';
import { HttpMethod } from '@tailflow/laravel-orion/lib/drivers/default/enums/httpMethod';

import Meetup from '@/models/meetup';
import User from '@/models/user';

declare global {
  interface Window {
    ym: any,
  }
}

Vue.use(Vuex);

const currentUserMeetups: Array<{
  id: number,
}> = [];

export default new Vuex.Store({
  state: {
    allMeetups: [],
    currentMeetup: {
      name: String,
    },
    vtbMeetupFeedback: {
      // отзыв отправлен в текущей сессии
      success: false,
      // отзыв был отправлен равнее - форма фидбэка не показывается
      done: false,
      selectedBook: null,
      error: false,
      item: {
        books: [],
        id: null,
        is_recommended: null,
        overall: null,
        rating: null,
        suggestions: null,
      },
    },
    isAllMeetupsLoading: false,
    isCurrentMeetupLoading: false,
    currentUser: <User>{},
    currentUserMeetups,
    specializations: [],
    tgCode: 0,
    abVariant: 'geeckoId',
    calendarLink: null,
    calendarFile: '',
  },
  mutations: {
    SET_ALL_MEETUPS(state, payload) {
      state.allMeetups = payload;
    },
    SET_IS_ALL_MEETUPS_LOADING(state, payload) {
      state.isAllMeetupsLoading = payload;
    },
    SET_CURRENT_MEETUP(state, payload) {
      state.currentMeetup = payload;
    },
    SET_IS_CURRENT_MEETUP_LOADING(state, payload) {
      state.isCurrentMeetupLoading = payload;
    },
    SET_CURRENT_USER(state, payload) {
      state.currentUser = payload;
    },
    SET_CURRENT_USER_MEETUPS(state, payload) {
      state.currentUserMeetups = payload;
    },
    SET_SPECIALIZATIONS(state, payload) {
      state.specializations = payload;
    },
    SET_TG_CODE(state, payload) {
      state.tgCode = payload;
    },
    SET_AB_VARIANT(state, payload) {
      state.abVariant = payload;
    },
    SET_CALENDAR_LINK(state, payload) {
      state.calendarLink = payload;
    },
    SET_CALENDAR_FILE(state, payload) {
      state.calendarFile = payload;
    },
    SET_FEEDBACK_DATA(state, payload) {
      state.vtbMeetupFeedback.item = payload;
    },
    SET_FEEDBACK_SUCCESS(state) {
      state.vtbMeetupFeedback.success = true;
    },
    SET_FEEDBACK_DONE(state) {
      state.vtbMeetupFeedback.done = true;
    },
    SET_FEEDBACK_ERROR(state, payload) {
      state.vtbMeetupFeedback.error = payload;
    },
    SET_FEEDBACK_BOOK(state, payload) {
      state.vtbMeetupFeedback.selectedBook = payload;
    },
  },
  getters: {
    isLogined(state) {
      return state.currentUser?.$attributes?.id;
    },
    isLoginedUnsigned(state) {
      return state.currentUser?.$attributes?.id
        && !state.currentUser?.$attributes?.geecko_user_id;
    },
    userPhoto(state) {
      return state.currentUser?.$attributes?.photo;
    },
  },
  actions: {
    async getMeetups({ commit }, tag) {
      commit('SET_IS_ALL_MEETUPS_LOADING', true);
      try {
        if (tag) {
          const meetups = await Meetup.$query()
            .sortBy('starts_at', SortDirection.Desc)
            .filter('speeches.tags.name', FilterOperator.Equal, tag)
            .search();
          commit('SET_ALL_MEETUPS', meetups.map((m) => m.$attributes));
        } else {
          const meetups = await Meetup.$query().sortBy('starts_at', SortDirection.Desc).search();
          commit('SET_ALL_MEETUPS', meetups.map((m) => m.$attributes));
        }
      } catch (e) {
        console.error('Meetups loading error', e);
      }
      commit('SET_IS_ALL_MEETUPS_LOADING', false);
    },
    async getMeetup({ commit }, meetupId) {
      commit('SET_IS_CURRENT_MEETUP_LOADING', true);
      try {
        const meetup = await Meetup.$query().with(['speeches.speakers', 'speeches.tags']).find(meetupId);
        commit('SET_CURRENT_MEETUP', {
          ...meetup.$attributes,
        });
      } catch (e) {
        console.error('Meetup loading error', e);
      }
      commit('SET_IS_CURRENT_MEETUP_LOADING', false);
    },
    async getClosestMeetup({ state, commit, dispatch }) {
      commit('SET_IS_CURRENT_MEETUP_LOADING', true);
      try {
        const now = new Date();
        const meetups = await Meetup.$query().with(['speeches.speakers', 'speeches.tags']).sortBy('starts_at', SortDirection.Asc).filter('starts_at', FilterOperator.GreaterThanOrEqual, now.toISOString())
          .search(1);
        commit('SET_CURRENT_MEETUP', {
          ...meetups[0]?.$attributes,
        });
        const userMeetup = state.currentUserMeetups
          .find((m) => m.id === meetups[0]?.$attributes?.id);
        if (userMeetup) {
          await dispatch('generateCalendarFile', meetups[0]?.$attributes?.id);
        }
      } catch (e) {
        console.error('Meetup loading error', e);
      }
      commit('SET_IS_CURRENT_MEETUP_LOADING', false);
    },
    async fetchUser({ commit }) {
      const clientWrapper = Orion.makeHttpClient();
      try {
        const user = await clientWrapper.request('/users/current', HttpMethod.GET);
        const userObject = User.$query().hydrate(user.data.data);
        commit('SET_CURRENT_USER', userObject);
        const meetups = await userObject.meetups().get();
        commit('SET_CURRENT_USER_MEETUPS', meetups.map((m) => m.$attributes));
        return true;
      } catch (e) {
        commit('SET_CURRENT_USER', {});
        commit('SET_CURRENT_USER_MEETUPS', []);
        return false;
      }
    },
    async fetchEnums({ commit }) {
      const clientWrapper = Orion.makeHttpClient();
      const specializationsData = await clientWrapper.request('/enums/tags', HttpMethod.GET);
      const allSpecs = specializationsData.data.data.reduce((
        acc: any, current: { id: any; name: any; specializations: any; },
      ) => {
        const { id, name } = current;
        acc.push({
          id,
          name,
        });

        return acc;
      }, []);
      commit('SET_SPECIALIZATIONS', allSpecs);
    },
    async subscribeMeetup({ state }, meetupId) {
      state.currentUser.meetups().attach([meetupId]);
      if (window.ym) {
        window.ym(75349582, 'reachGoal', 'signin');
      }
    },
    async updateReminder({ state }, { meetupId, payload }) {
      state.currentUser.meetups().updatePivot(meetupId, payload);
    },
    async generateTelegramCode({ commit, getters }, meetupId) {
      const clientWrapper = Orion.makeHttpClient();
      let tgCodeResult;
      if (getters.isLogined || getters.isLoginedUnsigned) {
        tgCodeResult = await clientWrapper.request(`/meetups/${meetupId}/pending-subscriptions`, HttpMethod.POST, {}, {
          type: 'telegram',
          remind_in: '3days',
          remind_id: '3days',
        });
      }
      const tgCodeData = tgCodeResult?.data?.data;
      commit('SET_TG_CODE', tgCodeData.code);
    },
    async generateCalendarLink({ commit, getters }, meetupId) {
      const clientWrapper = Orion.makeHttpClient();
      let googleCalendarResult;
      if (getters.isLogined || getters.isLoginedUnsigned) {
        googleCalendarResult = await clientWrapper.request(`/meetups/${meetupId}/google-calendar-link`, HttpMethod.GET);
      }
      const googleLinkData = googleCalendarResult?.data;
      commit('SET_CALENDAR_LINK', googleLinkData.link);
    },
    async generateCalendarFile({ commit, getters }, meetupId) {
      const clientWrapper = Orion.makeHttpClient();
      let calendarResult;
      if (getters.isLogined || getters.isLoginedUnsigned) {
        calendarResult = await clientWrapper.request(`/meetups/${meetupId}/calendar`, HttpMethod.GET);
      }
      const calendarData = calendarResult?.data;
      commit('SET_CALENDAR_FILE', calendarData);
    },
    downloadCalendarFile({ state }) {
      if (state.calendarFile.length) {
        const blob = new Blob([state.calendarFile], { type: 'text/calendar' });
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = `${state.currentMeetup.name}.ics`;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    },
    login(_, meetupId) {
      const redirectUrl = window.location.href;
      window.location.href = `${process.env.VUE_APP_API_URL}oauth/redirect?redirect_url=${redirectUrl}`;
    },
    async logout({ commit }) {
      const clientWrapper = Orion.makeHttpClient();
      await clientWrapper.request(`${process.env.VUE_APP_API_URL}logout`, HttpMethod.POST).catch((e) => {
        console.error('error', e);
      }).finally(() => {
        commit('SET_CURRENT_USER', {});
        commit('SET_CURRENT_USER_MEETUPS', []);
        localStorage.clear();
        window.location.reload();
      });
    },
    async register(_, payload) {
      const clientWrapper = Orion.makeHttpClient();
      const res = await clientWrapper.request(`${process.env.VUE_APP_API_URL}register`, HttpMethod.POST, {}, payload);
      return res;
    },
    async fetchFeedbackData({ commit }, meetupId) {
      const clientWrapper = Orion.makeHttpClient();
      try {
        const { data } = await clientWrapper.request(`/meetups/${meetupId}/feedback`, HttpMethod.GET);
        if (data.data.overall || data.data.is_recommended || data.data.suggestions) {
          commit('SET_FEEDBACK_SUCCESS');
          commit('SET_FEEDBACK_DONE');
        }
        commit('SET_FEEDBACK_DATA', data.data);
      } catch (e) {
        commit('SET_FEEDBACK_ERROR', `fetch book error, ${e}`);
      }
    },
    async sendFormFeedback({ commit }, payload) {
      const clientWrapper = Orion.makeHttpClient();
      const { formData, meetupId } = payload;
      try {
        const { data } = await clientWrapper.request(`/meetups/${meetupId}/feedbacks`, HttpMethod.POST, {}, formData);
        commit('SET_FEEDBACK_DATA', data.data);
      } catch (e) {
        commit('SET_FEEDBACK_ERROR', `send form feedback error, ${e}`);
      }
    },
    async updateFeedback({ commit }, payload) {
      const clientWrapper = Orion.makeHttpClient();
      const { formData, meetupId, feedbackId } = payload;
      try {
        const { data } = await clientWrapper.request(`/meetups/${meetupId}/feedbacks/${feedbackId}`, HttpMethod.PATCH, {}, formData);
        commit('SET_FEEDBACK_DATA', data.data);
        commit('SET_FEEDBACK_SUCCESS');
      } catch (e) {
        commit('SET_FEEDBACK_ERROR', `send form feedback error, ${e}`);
      }
    },
    async selectFormFeedbackBook({ commit }, payload) {
      commit('SET_FEEDBACK_BOOK', payload);
    },
    async feedbackDone({ commit }, payload) {
      commit('SET_FEEDBACK_DONE', payload);
    },
  },
  modules: {},
});
