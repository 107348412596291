<template>
  <svg
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 18C36 27.941 27.941 36 18 36C8.06 36 0 27.941 0 18C0
      8.06 8.06 0 18 0C27.941 0 36 8.06 36 18Z"
      :fill="disabled ? '#d2d2d2' : '#FFCC4D'"
    />
    <path
      d="M11.5 20C12.8807 20 14 18.433 14 16.5C14 14.567 12.8807
      13 11.5 13C10.1193 13 9 14.567 9 16.5C9 18.433 10.1193 20 11.5 20Z"
      :fill="disabled ? '#4b4b4b' : '#664500'"
    />
    <path
      d="M24.5 20C25.8807 20 27 18.433 27 16.5C27 14.567 25.8807
      13 24.5 13C23.1193 13 22 14.567 22 16.5C22 18.433 23.1193 20 24.5 20Z"
      :fill="disabled ? '#4b4b4b' : '#664500'"
    />
    <path
      d="M25 26H11C10.448 26 10 25.553 10 25C10 24.447 10.448 24 11 24H25C25.553 24 26
      24.447 26 25C26 25.553 25.553 26 25 26Z"
      :fill="disabled ? '#4b4b4b' : '#664500'"
    />
  </svg>
</template>

<script>
export default {
  name: 'Rate3',
  props: {
    disabled: Boolean,
  },
};
</script>
