<template>
  <div class="vtb-meetup-video">
    <div class="vtb-meetup-video__container" v-if="!isLogined">
      <div class="vtb-meetup-video__title">
        Авторизуйтесь, чтобы просмотреть запись митапа
      </div>
      <div class="vtb-meetup-video__buttons">
        <VtbButton
          isMedium
          isRounded
          is-bold
          class="vtb-meetup-video__button"
          @click="login"
        >
          <template #icon>
            <img :src="$options.geeckoLogo" />
          </template>
          Зарегистрироваться
        </VtbButton>
        <a
          @click="login"
          class="vtb-meetup-video__button vtb-meetup-video__link"
        >
          Войти
        </a>
      </div>
    </div>
    <div class="laptop:grid laptop:grid-cols-12" v-else>
      <div
        class="vtb-closest-meetup__video laptop:col-span-8"
        style="padding-bottom: 56.25%"
      >
        <iframe
          :src="meetup.video"
          :title="meetup.name"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write;
            encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>
      </div>
      <div
        class="col-span-4 mt-40 tablet:mt-80 relative h-0 overflow-hidden max-w-full hidden
          laptop:block"
        style="padding-bottom: 112.5%"
      >
        <iframe
          class="absolute top-0 left-0 w-full h-full"
          allowfullscreen=""
          frameborder="0"
          height="270"
          :src="chatUrl"
        >
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import VtbButton from '@/components/VtbButton.vue';
import geeckoLogo from '@/assets/geecko-logo.svg';

export default {
  name: 'VtbMeetupVideo',
  geeckoLogo,
  components: {
    VtbButton,
  },
  props: {
    meetup: Object,
  },
  computed: {
    isLogined() {
      return this.$store.getters.isLogined;
    },
    chatUrl() {
      const videoId = this.meetup.video.split('/').pop();
      return `https://www.youtube.com/live_chat?v=${videoId}&embed_domain=vtb-meetups.geecko.com`;
    },
  },
  methods: {
    login() {
      this.$store.dispatch('login', this.meetup.id);
    },
  },
};
</script>

<style lang="scss">
.vtb-meetup-video {
  @apply w-full;

  &__container {
    @apply w-full max-w-500 px-10 pt-24 pb-32 bg-gray rounded-md mt-48;

    @media screen and (min-width: 440px) {
      @apply px-40;
    }
  }

  &__title {
    @apply font-bold text-24 leading-36;
    max-width: 364px;
  }

  &__buttons {
    @apply flex-col flex items-center w-full mt-32;

    @media screen and (min-width: 440px) {
      @apply flex-row;
    }
  }

  &__button {
    @apply w-full mr-0;

    &:not(:last-child) {
      @apply mb-16;

      @media screen and (min-width: 440px) {
        @apply mr-16 mb-0;
      }
    }

    @media screen and (min-width: 440px) {
      @apply w-auto;
    }
  }

  &__link {
    @apply font-bold leading-24 text-center px-24 py-12 border border-solid
         border-black rounded-full cursor-pointer;
    transition: all 0.2s;

    &:hover,
    &:focus {
      @apply text-white bg-black opacity-80;
    }

    &:active {
      @apply opacity-90;
    }
  }

  &__frame {
    @apply w-full h-352;

    @media screen and (min-width: 600px) {
      @apply w-full h-484;
    }

    @media screen and (min-width: 1024px) {
      @apply w-full h-632;
    }
  }
}
</style>
