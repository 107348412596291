import { Model } from '@tailflow/laravel-orion/lib/model';
/* eslint-disable */
class Meetup extends Model<{
  name: string,
  description: string
}> {
  public $resource(): string {
    return 'meetups';
  }
}

export default Meetup;
