<template>
  <section
    class="vtb-closest-meetup"
    :class="{
      'vtb-closest-meetup--breadcrumbs': breadcrumbs,
    }"
    :style="{
      'padding-bottom': isSubscribed && !isPastMeetup ? '0px' : null,
    }"
  >
    <div class="vtb-container">
      <VtbBreadcrumbs v-if="breadcrumbs" class="mb-32" is-black />
      <div v-if="!isPastMeetup" class="vtb-closest-meetup__label">
        Онлайн митап
      </div>
      <div class="vtb-closest-meetup__title">
        {{ meetup.name }}
      </div>
      <div v-if="meetup.subtitle" class="vtb-closest-meetup__subtitle">
        {{ meetup.subtitle }}
      </div>
      <div class="vtb-closest-meetup__content">
        <div class="vtb-closest-meetup__info laptop:col-span-6">
          <div class="vtb-closest-meetup__description">
            <!-- <p v-for="(p, i) in descParagraphs" :key="i">
              {{ p }}
            </p> -->
            <vue-markdown :anchorAttributes="{target: '_blank'}">
            {{ descParagraphs }}
            </vue-markdown>

          </div>
          <tags v-if="allTags && allTags.size > 0" :tags="allTags" class="mt-4" />
        </div>
        <div
          class="vtb-closest-meetup__right mt-36 laptop:mt-0 laptop:col-start-8 laptop:col-span-4"
        >
          <div class="flex flex-col">
            <div>
              <img class="w-36" :src="$options.calendar" />
              <div class="text-24 leading-44 font-vtb-demibold mt-4">
                {{ meetupDate }}
              </div>
            </div>
            <div class="mt-8" v-if="!isPastMeetup && isSubscribed">
              <a
                class="vtb-closest-meetup__calendar-button"
                :href="calendarLink"
                target="_blank"
                @click="addCalendar"
              >
                Добавить в календарь
              </a>
            </div>
            <div v-if="!isPastMeetup" class="mt-28 laptop:mt-42">
              <img class="w-36" :src="$options.chat" />
              <div class="text-14 leading-22 font-vtb-demibold mt-4">
                Дарим футболки за лучшие <br />
                вопросы
              </div>
            </div>
          </div>
        </div>
      </div>
      <VtbMeetupVideo :meetup="meetup" v-if="isMeetupAvailable" />
      <VtbSubscribeBlock :meetup="meetup" />
    </div>
  </section>
</template>

<script>
import VueMarkdown from 'vue-markdown';

import calendar from '@/assets/icons/calendar.svg';
import chat from '@/assets/icons/chat.svg';

import VtbBreadcrumbs from '@/components/VtbBreadcrumbs.vue';
import VtbSubscribeBlock from '@/components/VtbSubscribeBlock.vue';
import VtbMeetupVideo from '@/components/VtbMeetupVideo.vue';

import { formatDate } from '@/utils';
import Tags from './Tags.vue';

const AVAILABLE_BEFORE = 90 * 60 * 1000; // 1.5h before meetup start
const AVAILABLE_AFTER = 60 * 60 * 1000; // 1h after meetup end

export default {
  calendar,
  chat,
  props: {
    noHead: Boolean,
    meetup: Object,
    breadcrumbs: Boolean,
  },
  computed: {
    meetupDate() {
      return formatDate(this.meetup.starts_at, true);
    },
    specializationTags() {
      return this.meetup.tags
        .map((s) => {
          const found = this.$store.state.specializations.find((ss) => ss.id === s);
          return found ? found.name : null;
        })
        .filter(Boolean);
    },
    isLogined() {
      return this.$store.getters.isLogined;
    },
    userMeetups() {
      return this.$store.state.currentUserMeetups;
    },
    isSubscribed() {
      const found = this.userMeetups.find((m) => m.id === this.meetup.id);
      return !!found;
    },
    shareUrl() {
      const meetupDesc = `Привет!
      Я участвую в ${this.meetup.name}.
      Если интересно - присоединиться можно по ссылке: ${window.location.href}.
      Буду рад видеть!`;
      const imgUrl = `${window.location.protocol}//${window.location.hostname}/og_image.jpg`;
      return `${process.env.VUE_APP_API_URL}og?url=${window.location.href}&title=${this.meetup.name}&description=${meetupDesc}&meetup=${this.meetup.id}&image=${imgUrl}`;
    },
    descParagraphs() {
      // const meetupDesc = this.meetup.description;
      // if (meetupDesc && meetupDesc.length) {
      //   return meetupDesc.split('\n');
      // }
      // return [];
      return this.meetup.description;
    },
    timeSum() {
      return this.meetup.speeches
        ? this.meetup.speeches.reduce((acc, curr) => acc + curr.duration, 0)
        : 0;
    },
    isPastMeetup() {
      const meetupDate = new Date(this.meetup.starts_at);
      return meetupDate.getTime() < Date.now();
    },
    features() {
      return [
        {
          title: 'Бесплатно',
          icon: 'heart',
        },
        {
          title: 'Крутые спикеры',
          icon: 'star',
        },
        {
          title: 'Дискуссии',
          icon: 'mic',
        },
      ];
    },
    isMeetupAvailable() {
      const isVideoAvailable = this.meetup.video && this.meetup.video.includes('embed');

      if (!isVideoAvailable) {
        return false;
      }

      if (this.isLogined) {
        return true;
      }

      const meetupStart = new Date(this.meetup.starts_at);
      const meetupDuration = this.meetup.speeches && this.meetup.speeches.length
        ? this.meetup.speeches.reduce((acc, current) => {
          if (current.duration) {
            // eslint-disable-next-line no-param-reassign
            acc += current.duration * 60 * 1000;
          }

          return acc;
        }, 0)
        : 0;

      const meetupEnd = new Date(meetupStart.getTime() + meetupDuration);

      const availableBefore = new Date(meetupStart.getTime() - AVAILABLE_BEFORE);
      const availableAfter = new Date(meetupEnd.getTime() + AVAILABLE_AFTER);

      const now = Date.now();
      return now >= availableBefore.getTime();
    },
    calendarLink() {
      return `${process.env.VUE_APP_API_URL}api/meetups/${this.meetup.id}/calendar`;
    },
    allTags() {
      return new Set(this.meetup.speeches.map((item) => item.tags).flat().map((item) => item.name));
    },
  },
  components: {
    VueMarkdown,
    // VtbTags,
    VtbBreadcrumbs,
    VtbSubscribeBlock,
    VtbMeetupVideo,
    Tags,
    // VtbShare,
  },
  methods: {
    addCalendar() {
      if (window.ym) {
        window.ym(75349582, 'reachGoal', 'add-calendar');
      }
    },
  },
};
</script>

<style lang="scss">
.vtb-closest-meetup {
  @apply bg-white pt-24 tablet:pt-56 rounded-2xl pb-80;

  &__label {
    @apply text-20 leading-28 text-red-button font-vtb-demibold;
  }

  &__title {
    @apply mt-8 text-24 leading-28 laptop:text-40 laptop:leading-48 text-black font-vtb-demibold;
    @apply laptop:w-784 laptop:text-40 laptop:leading-52;
  }

  &--breadcrumbs {
    @apply pt-16;
  }

  &__subtitle {
    @apply mt-8 text-24 leading-28 laptop:text-40 laptop:leading-48
      text-subtitle-gray font-vtb-light;
  }

  &__content {
    @apply grid grid-cols-1 laptop:grid-cols-12 mt-32 laptop:mt-60;
  }

  &__image-block {
    @apply mt-32;
    @apply tablet:flex tablet:items-start laptop:flex-col laptop:items-center;
  }

  &__image {
    @apply w-full rounded-lg;
    @apply tablet:w-378;
  }

  &__date {
    @apply text-32 leading-44 text-black font-vtb-demibold mt-40;
  }

  &__description {
    @apply text-18 leading-32 font-vtb-book;

    & a {
      color: #4099de;
      border-bottom: 1px solid #4099de;

      &:hover {
        border-bottom: 1px solid transparent;
      }
    }

  }

  &__info {
  }

  &__features {
    @apply grid grid-cols-2 gap-32;
    @apply tablet:grid-cols-3;
  }

  &__video {
    @apply mt-40 tablet:mt-80 relative h-0 overflow-hidden max-w-full;
    iframe {
      @apply absolute top-0 left-0 w-full h-full;
    }
  }

  &__calendar-button {
    @apply px-8 py-4 text-14 leading-22 font-vtb-book;
    background: linear-gradient(0deg, #eaedf5, #eaedf5), linear-gradient(0deg, #ffffff, #ffffff);
    border-radius: 4px;

    &:hover {
      background: linear-gradient(0deg, #c9cedd, #c9cedd), linear-gradient(0deg, #ffffff, #ffffff);
    }
    &:focus {
      background: linear-gradient(0deg, #a8b2cb, #a8b2cb), linear-gradient(0deg, #ffffff, #ffffff);
    }
  }
}
</style>
