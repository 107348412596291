import { Model } from '@tailflow/laravel-orion/lib/model';
import { BelongsToMany } from '@tailflow/laravel-orion/lib/drivers/default/relations/belongsToMany';

import Meetup from './meetup';
/* eslint-disable */
class User extends Model<{
  name: string,
  description: string
  photo: string,
  geecko_user_id: number,
}> {
  public meetups(): BelongsToMany<Meetup> {
    return new BelongsToMany(Meetup, this);
  }

  public $resource(): string {
    return 'users';
  }
}

export default User;
