<template>
  <svg
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 18C36 27.941 27.941 36 18 36C8.059 36 0 27.941 0 18C0 8.059
      8.059 0 18 0C27.941 0 36 8.059 36 18Z"
      :fill="disabled ? '#d2d2d2' : '#FFCC4D'"
    />
    <path
      d="M18 21C14.377 21 11.973 20.578 9 20C8.321 19.869 7 20 7 22C7 26 11.595 31 18
      31C24.404 31 29 26 29 22C29 20 27.679 19.868 27 20C24.027 20.578 21.623 21 18 21Z"
      :fill="disabled ? '#4b4b4b' : '#664500'"
    />
    <path
      d="M9 22C9 22 12 23 18 23C24 23 27 22 27 22C27 22 25 26 18 26C11 26 9 22 9 22Z"
      fill="white"
    />
    <path
      d="M15.6808 4.41279L11.1388 5.21379L8.7988 0.960785C8.5408 0.491785
      8.0108 0.240785 7.4868 0.332785C6.9598 0.425785 6.5498 0.843785 6.4678
      1.37179L5.7228 6.16879L1.1808 6.96979C0.645797 7.06379 0.232797 7.49479
      0.159797 8.03379C0.0867974 8.57279 0.370797 9.09679 0.862797 9.33079L4.9328
      11.2628L4.1848 16.0748C4.1018 16.6108 4.3738 17.1388 4.8578 17.3838C5.0368
      17.4738 5.2288 17.5168 5.4198 17.5168C5.7468 17.5168 6.0698 17.3888 6.310
      17.1448L9.8228 13.5838L14.3408 15.7288C14.8308 15.9608 15.4148 15.8518 15.7868
      15.4568C16.1588 15.0618 16.2328 14.4728 15.9718 13.9978L13.6238 9.72978L16.7888
      6.52179C17.1708 6.13479 17.2578 5.54479 17.0058 5.06279C16.7518 4.58079 16.2128
      4.31979 15.6808 4.41279ZM20.3168 4.41279L24.8588 5.21379L27.1988 0.960785C27.4568
      0.491785 27.9868 0.240785 28.5108 0.332785C29.0368 0.425785 29.4468 0.843785 29.5288
      1.37179L30.2738 6.16879L34.8158 6.96979C35.3518 7.06379 35.7648 7.49379 35.8368
      8.03279C35.9088 8.57178 35.6258 9.09579 35.1338 9.32979L31.0638 11.2618L31.8118
      16.0738C31.8948 16.6098 31.6228 17.1378 31.1388 17.3828C30.9598 17.4728 30.7678 17.5158
      30.5768 17.5158C30.2498 17.5158 29.9268 17.3878 29.6858 17.1438L26.1738 13.5828L21.6558
      15.7278C21.1658 15.9598 20.5818 15.8508 20.2098 15.4558C19.8378 15.0608 19.7638 14.4718
      20.0248 13.9968L22.3728 9.72978L19.2078 6.52179C18.8258 6.13479 18.7388 5.54479
      18.9908 5.06279C19.2458 4.58079 19.7848 4.31979 20.3168 4.41279Z"
      :fill="disabled ? '#a6a6a6' : '#E95F28'"
    />
  </svg>
</template>

<script>
export default {
  name: 'Rate5',
  props: {
    disabled: Boolean,
  },
};
</script>
