<template>
  <article class="vtb-meetup">
    <VtbMeetup
      v-if="!isCurrentMeetupLoading"
      no-head
      breadcrumbs
      :meetup="currentMeetup"
      :meetups="allMeetups">
      <VtbBreadcrumbs />
    </VtbMeetup>
    <VtbLoader v-else :outer-height="138" />
    <VtbMeetupsList :meetup="currentMeetup" :meetups="allMeetups" />
  </article>
</template>

<script>
import store from '@/store';

import VtbMeetupsList from '@/components/VtbMeetupsList.vue';
import VtbMeetup from '@/components/VtbMeetup.vue';
import VtbBreadcrumbs from '@/components/VtbBreadcrumbs.vue';
import VtbLoader from '@/components/VtbLoader.vue';

export default {
  components: {
    VtbBreadcrumbs,
    VtbLoader,
    VtbMeetupsList,
    VtbMeetup,
  },
  computed: {
    isCurrentMeetupLoading() {
      return this.$store.state.isCurrentMeetupLoading;
    },
    allMeetups() {
      return this.$store.state.allMeetups;
    },
    currentMeetup() {
      return this.$store.state.currentMeetup;
    },
  },
  async beforeRouteUpdate(to, from, next) {
    if (store.getters.isLogined) {
      const userMeetup = store.state.currentUserMeetups
        .find((m) => m.id === +to.params.meetupId);
      if (userMeetup) {
        try {
          await store.dispatch('generateCalendarLink', to.params.meetupId);
          if (to.query.isSubscribed) {
            await store.dispatch('generateTelegramCode', to.params.meetupId);
          }
        } catch (e) {
          console.error('Error getting calendar link', e);
        }
      }
      next();
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.vtb-meetup {
  &__breadcrubms {
    @apply pt-58;
  }

  &__closest {
    @apply pt-8;
  }
}
</style>
