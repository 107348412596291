var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"vtb-nav",class:{
    'vtb-nav--minimized': _vm.minimized,
    'vtb-nav--mobile': _vm.showNav,
    relative: !_vm.showNav,
  }},[_c('div',{staticClass:"vtb-nav__start flex justify-between"},[_c('router-link',{staticClass:"z-10",attrs:{"to":{ name: 'MainPage' }}},[_c('img',{staticClass:"vtb-nav__logo",attrs:{"src":_vm.$options.vtbLogo}})]),(!_vm.showNav)?_c('img',{staticClass:"vtb-nav__burger",attrs:{"src":_vm.$options.burger},on:{"click":function($event){_vm.showNav = true}}}):_c('img',{staticClass:"vtb-nav__burger mr-32",attrs:{"src":_vm.$options.cross},on:{"click":function($event){_vm.showNav = false}}})],1),_c('div',{staticClass:"vtb-nav__end relative z-10",class:{
      'vtb-nav__end--mobile': _vm.showNav == true,
      'desktop:ml-120': !_vm.showNav,
    }},[_c('router-link',{staticClass:"vtb-nav__link",attrs:{"to":{ name: 'AllMeetups' }},nativeOn:{"click":function($event){_vm.showNav = false}}},[_vm._v(" Все митапы ")]),_c('router-link',{staticClass:"vtb-nav__link",attrs:{"exact":"","to":{ name: 'MainPage' }},nativeOn:{"click":function($event){_vm.showNav = false}}},[_vm._v(" Ближайший митап ")]),_c('div',{staticClass:"vtb-nav__spacer"}),(_vm.isLogined)?_c('a',{staticClass:"vtb-nav__link tablet:hidden",attrs:{"href":"https://id.geecko.com/profile","target":"_blank"}},[_vm._v(" Мой профиль ")]):_vm._e(),(!_vm.isLogined)?_c('a',{staticClass:"vtb-nav__link",on:{"click":function($event){_vm.showNav = false;
        _vm.$store.dispatch('login');}}},[_vm._v(" Войти ")]):[_c('div',{staticClass:"vtb-nav__avatar-container"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hidePopup),expression:"hidePopup"}],staticClass:"vtb-nav__avatar",style:(_vm.avatarStyle),on:{"click":_vm.showPopup}}),_c('a',{staticClass:"vtb-nav__link tablet:hidden mt-0 ml-16",on:{"click":function($event){_vm.showNav = false;
            _vm.logout();}}},[_vm._v(" Выйти ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.popup),expression:"popup"}],staticClass:"vtb-nav__dropdown"},[_c('a',{staticClass:"vtb-nav__dropdown-link",attrs:{"href":"https://id.geecko.com/profile","target":"_blank"}},[_vm._v(" Мой профиль ")]),_c('div',{staticClass:"vtb-nav__dropdown-link",on:{"click":_vm.logout}},[_vm._v("Выйти")])])])]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }