<template>
  <div class="vtb-meetup">
    <VtbMeetupInfo
      class="vtb-meetup__closest"
      :no-head="noHead"
      :meetup="meetup"
      :breadcrumbs="breadcrumbs"
    >
    </VtbMeetupInfo>
    <VtbMeetupFeedback v-if="showFeedbackForm" ref="feedbackForm" />
    <!--<VtbNextMeetups :meetups="meetups" :meetup="meetup" />-->
    <VtbProgram :meetup="meetup" v-if="meetup.speeches && meetup.speeches.length" />
    <!--<VtbSpeakers :meetup="meetup" />-->
  </div>
</template>

<script>
import VtbMeetupInfo from '@/components/VtbMeetupInfo.vue';
// import VtbNextMeetups from '@/components/VtbNextMeetups.vue';
// import VtbSpeakers from '@/components/VtbSpeakers.vue';
import VtbProgram from '@/components/VtbProgram.vue';
import VtbMeetupFeedback from '@/components/VtbMeetupFeedback/index.vue';

export default {
  props: {
    noHead: Boolean,
    meetup: Object,
    meetups: Array,
    breadcrumbs: Boolean,
  },
  components: {
    VtbMeetupInfo,
    // VtbNextMeetups,
    // VtbSpeakers,
    VtbProgram,
    VtbMeetupFeedback,
  },
  computed: {
    isLogined() {
      return this.$store.getters.isLogined;
    },
    meetupFinished() {
      return new Date(this.meetup.starts_at) < new Date();
    },
    showFeedbackForm() {
      return (
        this.isLogined
        && this.meetupFinished
        && this.meetup.books
        && Array.isArray(this.meetup.books)
        && this.meetup.books.length
      );
    },
  },
  mounted() {
    if (Object.keys(this.$router.currentRoute.query).includes('feedback')
      && this.$refs.feedbackForm
    ) {
      this.$refs.feedbackForm.$el.scrollIntoView();
    }
  },
};
</script>
