<template>
  <section
    v-if="!done"
    class="vtb-meetup-feedback"
    :class="success ? 'vtb-meetup-feedback--success' : ''"
  >
    <button class="vtb-meetup-feedback__close" @click="closeFeedback">
      <img
        src="~@/assets/icons/close-icon.svg"
        alt="закрыть"
        class="vtb-meetup-feedback__close-pic"
      />
    </button>
    <div class="vtb-container">
      <h2 class="text-24 leading-32 font-vtb-demibold text-center mb-12">{{ title }}</h2>
      <div v-if="!success" class="vtb-meetup-feedback-block">
        <div v-if="success" class="text-14 text-center text-special-black mt-20">
          {{ description }}
        </div>
        <div class="vtb-meetup-feedback__more">
          <div>
            <div class="flex items-start">
              <img
                src="~@/assets/images/book-reward.svg"
                alt="Книга в подарок"
                class="w-80 h-104 block mr-24"
              />
              <div>
                <div class="font-bold text-18 mb-8 text-special-black">
                  Подарим книгу за ваш отзыв!
                </div>
                <p class="text-14 font-normal leading-20">
                  Вы сможете выбрать и скачать одну книгу из профессиональной литературы к митапу.
                </p>
              </div>
            </div>
          </div>
          <VtbMeetupFeedbackGrade
            :currentGrade="currentGrade"
            :meetupId="meetupId"
            :handleClick="sendForm"
            class="mt-20"
          />
          <VtbMeetupFeedbackForm
            :meetupId="meetupId"
            :feedbackId="formFeedback && formFeedback.id"
            :sendFormFeedback="updateFeedback"
          />
        </div>
      </div>
      <div v-else class="vtb-meetup-feedback-block">
        <VtbMeetupFeedbackSuccess
          :books="books"
          :selectedBook="selectedBook"
          :handleClick="selectBook"
        />
      </div>
    </div>
  </section>
</template>

<script>
import VtbMeetupFeedbackGrade from '@/components/VtbMeetupFeedback/VtbMeetupFeedbackGrade.vue';
import VtbMeetupFeedbackForm from '@/components/VtbMeetupFeedback/VtbMeetupFeedbackForm.vue';
import VtbMeetupFeedbackSuccess from '@/components/VtbMeetupFeedback/VtbMeetupFeedbackSuccess.vue';

export default {
  name: 'VtbMeetupFeedback',
  components: {
    VtbMeetupFeedbackGrade,
    VtbMeetupFeedbackForm,
    VtbMeetupFeedbackSuccess,
  },
  computed: {
    title() {
      let title = 'Оцените митап и получите книгу бесплатно!';

      if (this.$store.state.vtbMeetupFeedback.selectedBook) {
        title = 'Сейчас начнётся скачивание…';
      } else if (this.$store.state.vtbMeetupFeedback.item.id) {
        title = 'Спасибо за отзыв!';
      } else if (this.$store.state.vtbMeetupFeedback.success) {
        title = 'Спасибо! Выберите ваш подарок';
      }
      return title;
    },
    description() {
      let description = 'Выберите одну книгу, которую хотите получить в электронном виде';

      if (this.$store.state.vtbMeetupFeedback.selectedBook) {
        description = '';
      } else if (this.$store.state.vtbMeetupFeedback.item.books.length === 0) {
        description = '';
      }

      return description;
    },
    meetupId() {
      return this.$store.state.currentMeetup.id;
    },
    currentGrade() {
      return this.$store.state.vtbMeetupFeedback.item.rating;
    },
    formFeedback() {
      return this.$store.state.vtbMeetupFeedback.item;
    },
    selectedBook() {
      return this.$store.state.vtbMeetupFeedback.selectedBook;
    },
    books() {
      return this.$store.state.vtbMeetupFeedback.item.books;
    },
    success() {
      return this.$store.state.vtbMeetupFeedback.success;
    },
    done() {
      return this.$store.state.vtbMeetupFeedback.done;
    },
  },
  methods: {
    fetchFeedbackData() {
      this.$store.dispatch('fetchFeedbackData', this.meetupId);
    },
    sendForm(data) {
      this.$store.dispatch('sendFormFeedback', data);
    },
    updateFeedback(data) {
      this.$store.dispatch('updateFeedback', data);
    },
    selectBook(book) {
      this.$store.dispatch('selectFormFeedbackBook', book);
    },
    closeFeedback() {
      this.$store.dispatch('feedbackDone', true);
    },
  },
  mounted() {
    this.fetchFeedbackData();
  },
};
</script>

<style lang="scss" scoped>
.vtb-meetup-feedback {
  @apply relative bg-white mt-16 pt-40 tablet:pt-56 pb-48 tablet:pb-40 rounded-2xl;

  &--success {
    background: radial-gradient(115.22% 64.11% at 50% 0%, #aee2b0 0%, rgba(255, 255, 255, 0) 100%),
      #ffffff;
  }

  * {
    box-sizing: border-box;
  }

  &__close {
    @apply absolute right-32 top-32 outline-none;

    &:hover & {
      &-pic {
        @apply opacity-50;
      }
    }

    &-pic {
      @apply transition-opacity;
      width: 18px;
      height: 18px;
    }
  }

  &__more {
    @apply relative w-full mt-56 mx-auto px-48 py-40 bg-gray rounded-lg;
    max-width: 496px;
  }
}
</style>
