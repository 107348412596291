<template>
  <svg
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0
      8.05887 0 18C0 27.9411 8.05887 36 18 36Z"
      :fill="disabled ? '#d2d2d2' : '#FFCC4D'"
    />
    <path
      d="M18 21C14.377 21 11.973 20.578 9 20C8.321 19.869 7 20 7 22C7 26 11.595
      31 18 31C24.404 31 29 26 29 22C29 20 27.679 19.868 27 20C24.027 20.578 21.623 21 18 21Z"
      :fill="disabled ? '#4b4b4b' : '#664500'"
    />
    <path
      d="M9 22C9 22 12 23 18 23C24 23 27 22 27 22C27 22 25 26 18 26C11 26 9 22 9 22Z"
      fill="white"
    />
    <path
      d="M12 17C13.3807 17 14.5 15.433 14.5 13.5C14.5 11.567 13.3807 10 12
      10C10.6193 10 9.5 11.567 9.5 13.5C9.5 15.433 10.6193 17 12 17Z"
      :fill="disabled ? '#4b4b4b' : '#664500'"
    />
    <path
      d="M24 17C25.3807 17 26.5 15.433 26.5 13.5C26.5 11.567 25.3807 10 24
      10C22.6193 10 21.5 11.567 21.5 13.5C21.5 15.433 22.6193 17 24 17Z"
      :fill="disabled ? '#4b4b4b' : '#664500'"
    />
  </svg>
</template>
<script>
export default {
  name: 'Rate4',
  props: {
    disabled: Boolean,
  },
};
</script>
